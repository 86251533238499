import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
export default {
  // 创建/更新订单
  async orderAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/order/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 删除订单
  async orderDelete(params) {
    return await instance
      .delete(`${base}/m/order/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 修改订单状态为订单
  async orderUpdateBusiness(params) {
    return await instance
      .put(`${base}/m/order/orderUpdateBusiness`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取订单信息
  async orderGet(params) {
    return await instance
      .get(`${base}/m/order/get/${params.orderCode}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取订单列表信息
  async orderGetList(params) {
    return await instance
      .post(`${base}/m/order/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取业务订单列表信息
  async orderGetListBusiness(params) {
    return await instance
      .post(`${base}/m/order/queryBusiness`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //获取总销售和
  async queryAlltotalALl(params) {
    return await instance
      .post(`${base}/m/order/queryAlltotalAll`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 修改订单信息
  async orderPutUpdate(params) {
    console.log(2222222222222222222)
    return await instance
      .put(`${base}/m/order/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 汪威审批
  async orderPutUpdate1(params) {
    return await instance
      .put(`${base}/m/order/handleApprove`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderPutUpdateTest(params) {
    return await instance
      .get(`${base}/m/test/testOrder`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //客服主管退回
  async orderonfirmation(params) {
    return await instance
      .put(`${base}/m/order/orderonfirmation`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async technicalSupport(params) {
    return await instance
      .put(`${base}/m/order/technicalSupport`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //ding催办
  async reminder(params) {
    return await instance
      .put(`${base}/m/order/orderReminder`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //回退
  async Rollback(params) {
    return await instance
      .put(`${base}/m/order/updateRollback`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  async orderSalesmanPutUpdate(params) {
    return await instance
      .put(`${base}/m/order/updateSalesman`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 查看修改订单信息
  async orderPutUpdateView(params) {
    return await instance
      .put(`${base}/m/order/updateView`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //技术拒绝生产单
  async orderPutUpdateManufacture(params) {
    return await instance
      .put(`${base}/m/order/updateManufacture`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //根据产品名称搜索产品参数//修改更正
  async getUpdateProductName(params) {
    return await instance
      .get(`${base}/m/orderProduct/getUpdateProductName`, { params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //根据产品id搜索产品参数//修改更正
  async selectUpdateProductName(params) {
    return await instance
      .get(`${base}/m/orderProduct/selectUpdateProductName`, { params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  //根据合同号删除参数
  async getDeleteProductConfig(params) {
    return await instance
      .put(`${base}/m/orderProduct/getDeleteProductConfig`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 根据订单号获取订单报价单表信息
  async orderGetQuotation(params) {
    return await instance
      .get(`${base}/m/quotation/get/quotation/${params.orderCode}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 修改订单报价单表信息
  async orderPutQuotation(params) {
    return await instance
      .put(`${base}/m/quotation/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据订单号获取订单合同信息
  async orderGetContract(params) {
    return await instance
      .get(`${base}/m/contract/get/contract/${params.orderCode}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 修改订单合同信息
  async orderPutContractUpdate(params) {
    return await instance
      .put(`${base}/m/contract/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据订单号获取订单产品生产联络单信息
  async orderGetProductionLiaisonDoc(params) {
    return await instance
      .get(
        `${base}/m/production_liaison_doc/get/productionLiaisonDoc/${params.orderCode}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 修改订单产品图纸编号、备注
  async orderPutProductionLiaisonDocUpdateOrderProductInfo(params) {
    return await instance
      .put(`${base}/m/production_liaison_doc/updateOrderProductInfo`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //修改派单时间
  async orderProductionUpdateDispatchDate(params) {
    return await instance
      .put(
        `${base}/m/production_liaison_doc/updateOrderProductDispatchDate`,
        params
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //修改产品关键工艺
  async orderPutProductionKeyProductProcess(params) {
    return await instance
      .put(`${base}/m/production_liaison_doc/updateKeyProductProcess`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //修改产品参数
  async getUpdateProductConfig(params) {
    return await instance
      .put(`${base}/m/orderProduct/getUpdateProductConfig`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderreasonsForRefusal(params) {
    return await instance
      .put(`${base}/m/production_liaison_doc/orderReasonsForRefusal`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderReleaseCause(params) {
    return await instance
      .put(`${base}/m/order/orderReleaseCause`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderProductLine(params) {
    return await instance
      .post(`${base}/m/order/productLine`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderCRMERP(params) {
    return await instance
      .put(`${base}/m/order/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  //复制他人订单到自己购物车
  async copyToOneself(params) {
    return await instance
      .put(`${base}/m/order/copyToOneself`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 修改订单产品生产联络单信息
  async orderProductionLiaisonDocUpdate(params) {
    return await instance
      .put(`${base}/m/production_liaison_doc/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 导出生产联络单
  async productionLiaisonDoc(params) {
    return await instance
      .get(`${base}/m/production_liaison_doc/export/${params.orderCode}`, {
        headers: {
          expect: "100-continue",
          responseType: "blob",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 导出合同
  async contractExport(params) {
    return await instance
      .get(`${base}/m/contract/export/${params.orderCode}`, {
        headers: {
          expect: "100-continue",
          responseType: "blob",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //测试导出
  async contractPurchaseExport(params) {
    return await instance
      .post(`${base}/m/contract/exportPurchase`, {
        ...params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  async contractNonPurchaseExport(params) {
    console.log("Params sent to API:", params); // 打印发送到 API 的参数
    return await instance
      .post(`${base}/m/contract/exportNonPurchase`, {
        ...params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  async contractFrameExport(params) {
    console.log("Params sent to API:", params); // 打印发送到 API 的参数
    return await instance
      .post(`${base}/m/contract/exportFrame`, {
        ...params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //附加文件
  async exportPurchaseOrderHtml(params) {
    return await instance
      .get(`${base}/m/contract/exportPurchaseOrderHtml/${params.orderCode}`, {
        headers: {
          expect: "100-continue",
          responseType: "blob",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 导出报价单
  async quotationExport(params) {
    return await instance
      .get(`${base}/m/quotation/export/${params.orderCode}`, {
        headers: {
          expect: "100-continue",
          responseType: "blob",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品分类销量趋势：折线图
  async categoryOrderTrendStatistic(params) {
    return await instance
      .post(`${base}/m/statistic/categoryOrderTrendStatistic`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async categoryOrderTrendStatisticOne(params) {
    return await instance
      .post(`${base}/m/statistic/categoryOrderTrendStatisticOne`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品分类销量趋势：折线图 导出
  async categoryOrderTrendStatisticExport(params) {
    return await instance
      .post(`${base}/m/statistic/categoryOrderTrendStatistic/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 产品分类销量占比：饼状图
  async categorySalePer(params) {
    return await instance
      .post(`${base}/m/statistic/categorySalePer`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async OrderRankingList(params) {
    return await instance
      .post(`${base}/m/statistic/OrderRankingList`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async DepartmentalSales(params) {
    return await instance
      .post(`${base}/m/statistic/DepartmentalSales`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品分类销量占比：饼状图 导出
  async categorySalePerExport(params) {
    return await instance
      .post(`${base}/m/statistic/categorySalePer/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 产品销量数量：饼状图
  async productOrderStatistic(params) {
    return await instance
      .post(`${base}/m/statistic/productOrderStatistic`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品销量数量：饼状图 导出
  async productOrderStatisticExport(params) {
    return await instance
      .post(`${base}/m/statistic/productOrderStatistic/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 所有产品销量：柱状图
  async productOrderStatisticByDate(params) {
    return await instance
      .post(`${base}/m/statistic/productOrderStatisticByDate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 所有产品销量：柱状图 导出
  async productOrderStatisticByDateExport(params) {
    return await instance
      .post(`${base}/m/statistic/productOrderStatisticByDate/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 用户下单数：柱状图
  async queryUserOrderStatistic(params) {
    return await instance
      .post(`${base}/m/statistic/queryUserOrderStatistic`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 業務員
  async queryAllTotalSales(params) {
    return await instance
      .post(`${base}/m/statistic/queryAllTotalSales`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 用户下单数：柱状图 导出
  async queryUserOrderStatisticExport(params) {
    return await instance
      .post(`${base}/m/statistic/queryUserOrderStatistic/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品分类销售额：漏斗图
  async categoryOrderStatisticByDate(params) {
    return await instance
      .post(`${base}/m/statistic/categoryOrderStatisticByDate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 产品分类销售额：漏斗图-导出excel
  async categoryOrderStatisticByDateExport(params) {
    return await instance
      .post(`${base}/m/statistic/categoryOrderStatisticByDate/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 销售总数据
  async queryAllSaleByDate(params) {
    return await instance
      .post(`${base}/m/statistic/queryAllSaleByDate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // // 销售总数据订单
  // async queryAllSaleByDate(params) {
  //   return await instance
  //     .post(`${base}/m/statistic/queryAllSaleByDate`, params)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       message.error(error.msg || "服务器出错");
  //     });
  // },
  // 销售总数据 导出
  async queryAllSaleByDateExport(params) {
    return await instance
      .post(`${base}/m/statistic/queryAllSaleByDate/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
};
