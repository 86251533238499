import React from "react";

import { useState } from "react";
import ReactDOM from "react-dom";
// import CryptoJS from "crypto-js";
import { Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Table,
  Row,
  Col,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Checkbox,
  Divider,
  message,
  Cascader,
  InputNumber,
  Space,
  DatePicker,
  Upload,
} from "antd";
import { Modal } from "antd";
import { Steps } from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  SafetyCertificateOutlined,
  CheckOutlined,
  CloseOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
// import moment from "moment";
import api from "../../api";
import { find } from "lodash";
import { color, Model } from "echarts";
import { useForm } from "rc-field-form";
import data from "../../assets/js/data.json";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

const { Step } = Steps;
const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const EditUserForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [fileDataSource, setFiletDataSource] = useState([]);
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState(
    chooseUserInfo.pictureList || []
  );
  const [fileListVideo, setFileListVideo] = React.useState(
    chooseUserInfo.video || []
  );
  const [classifyArr, setClassifyArr] = React.useState([]);
  const [classifyConfigListArr, setClassifyConfigListArr] = React.useState([]);
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };
  // setInterval(function(){
  //   window.location.reload();
  // },180000);
  const imageProps = {
    ref: "upload",
    name: "file",
    listType: "picture",
    fileList: fileList,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      res && setFileList([...fileList, res.data]);
      console.log(fileList);
    },
    onRemove: (file) => {
      console.log(file);
      setFileList([...fileList.filter((e) => e != file)]);
      console.log(fileList);
    },
  };

  const videoProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListVideo,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      res && setFileListVideo([res.data]);
    },
    onRemove: (file) => {
      setFileListVideo([]);
    },
  };

  async function getConfigList(params) {
    return await api.categoryGetConfig(params).then((res) => {
      if (res.code === 200) {
        return res.data;
      }
    });
  }

  React.useEffect(() => {
    form.resetFields();
    if (chooseUserInfo.id) {
      api.orderGet({ orderCode: chooseUserInfo.orderCode }).then((res) => {
        // console.log(res);
      });
    }

    if (visible || !visible) {
      form.resetFields();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置产品信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        form.setFieldsValue({
          pictureList: fileList,
          video: fileListVideo[0],
        });
        onCreate(form.getFieldsValue());
        // console.log(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Form.Item name="id">
            <Input type="hidden" value={chooseUserInfo.id} disabled />
          </Form.Item>
          <Form.Item name="title" label="产品标题">
            <Input />
          </Form.Item>
          <Form.Item name="subtitle" label="副标题">
            <Input />
          </Form.Item>
          <Form.Item name="categoryId" label="产品类目">
            <Cascader
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={
                (classifyArr && classifyArr.length && classifyArr) || false
              }
              changeOnSelect={true}
              onChange={(val) => {
                console.log(val);
                form.setFieldsValue({
                  categoryId: val,
                });
                getConfigList({
                  id: val[val.length - 1],
                }).then((res) => {
                  if (res && res.length) {
                    setClassifyConfigListArr(
                      res?.map((e) => ({
                        value: e.id,
                        label: e.title,
                      })) || []
                    );
                  }
                });
              }}
            />
          </Form.Item>
          <Form.Item name="configTemplateId" label="类目配置">
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={
                (classifyConfigListArr &&
                  classifyConfigListArr.length &&
                  classifyConfigListArr) ||
                false
              }
            />
          </Form.Item>
          <Form.Item name="price" label="产品价格">
            <InputNumber max={100} />
          </Form.Item>
          <Form.Item name="isShelves" label="是否上架">
            <Radio.Group>
              <Radio value={true}>上架</Radio>
              <Radio value={false}>下架</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="productDesc" label="产品描述">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item name="pictureList" label="图片">
            <Upload {...imageProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="video" label="视频">
            <Upload {...videoProps}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传视频</div>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
class quote extends React.Component {
  state = {
    dataSource: [],
    dataindex: null,
    dataindexOne: null,
    userArr: [],
    isModalOpen: false,
    isModalOpenOne: false,
    isModalOpenTwo: false,
    pagination: {
      pageNo: 1,
      pageSize: 20,
    },
    showEdit: false,
    showDetail: false,
    loading: false,
    chooseUserInfo: {},
    fileId: "",
    fileListCC: [], // 文件列表
  };
  myMethod(e) {
    this.fileId = e;
    console.log(this.fileId);
  }
  handleFormSubmit = (values) => {
    // console.log(values.calibrationCertification); // 打印选择的值
    this.setState({ isModalOpenTwo: false });
    console.log(values.calibrationCertification);
    // console.log(this.state.e); // 获取到传递进来的e值
    this.changeStatusERPCRM(this.state.e, values.calibrationCertification);
  };

  //拒绝原因
  showModal = (e) => {
    this.state.dataindex = e;
    this.setState({ isModalOpen: true, e });
  };
  handleOk = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  //上传合同+料号
  showModalOne = (e) => {
    this.state.dataindexOne = e;
    this.setState({ isModalOpenOne: true, e });
  };
  handleOkOne = () => {
    this.setState({ isModalOpenOne: false });
  };
  handleCancelOne = () => {
    this.setState({ isModalOpenOne: false });
  };

  showModalTwo = (e) => {
    console.log(e);
    this.setState({
      isModalOpenTwo: true,
      e: e,
    });
  };

  handleOkTwo = () => {
    const form = this.form;
    form.validateFields().then((values) => {
      this.setState({ isModalOpenTwo: false });
    });
  };

  handleCancelTwo = () => {
    this.setState({ isModalOpenTwo: false });
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.getUserArr();
    this.fetch({ pagination });
  }
  async getUserArr() {
    let result = await api.getUserList({ pageNo: 1, pageSize: 500 });
    if (result.code == 200) {
      this.setState({
        userArr: result.data.records,
      });
    }
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  async changeStatusERPCRM(e, value) {
    console.log(e);
    const res = await api.orderCRMERP({
      id: e.id,
      userId: e.userId,
      orderCode: e.orderCode,
      pushOrNot: value,
      orderStatus: 2,
    });
  }
  async changeStatus(info, value) {
    const res = await api.orderSalesmanPutUpdate({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: value,
    });
    if (res.data == true) {
      const { pagination } = this.state;
      this.fetch({ pagination });
      message.success("审核成功");
    } else {
      message.error("审核条件不成立");
    }
  }
  handleTableChange = (pagination, sorter) => {
    pagination.pageNo = pagination.current;
    const { filters } = this.state;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters: { ...filters },
    });
  };
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let data = await api.orderGetListBusiness({
      ...params.pagination,
      ...params.filters,
    });
    if (!data || data.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      dataSource: (data && data.data.records) || [],
      id: (data && data.data.records.id) || [],
      pagination: {
        pageNo: (data && data.data.pageNo) || 1,
        pageSize: (data && data.data.pageSize) || 20,
        total: (data.data && data.data.totalRecords) || 1,
      },
      filters: { ...params.filters },
    });
  };

  showDeleteConfirm = (info) => {
    let _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除订单，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      async onOk() {
        let result = await api.orderDelete(info);
        if (!result || result.code !== 200) {
          message.error("不可删除");
        }
        if (result.code == 200) {
          message.success("删除成功！");
        }
        const { pagination } = _t.state;
        _t.fetch({ pagination });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  showorderUpdateBusiness = (info) => {
    let _t = this;
    const {
      inputValue1,
      inputValue2,
      inputValue3,
      inputValue4,
      inputValue5,
      inputValue6,
      inputValue7,
    } = this.state; // 假设输入框的值保存在state中的inputValue1、inputValue2、inputValue3中
    confirm({
      title: "此操作将会由报价单转为正式订单",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>此操作将删除此报价单，转为订单，是否继续？</p>
          {/* <Input
            placeholder="客户名称"
            value={inputValue2}
            onChange={(e) => this.setState({ inputValue1: e.target.value })}
          />
          <Radio.Group
            onChange={(e) => this.setState({ inputValue2: e.target.value })}
            value={inputValue2}
          >
            <Radio.Button value="新签">新签</Radio.Button>
            <Radio.Button value="复购">复购</Radio.Button>
            <Radio.Button value="维修">维修</Radio.Button>
          </Radio.Group>
          <Input
            placeholder="客户名称"
            value={inputValue3}
            onChange={(e) => this.setState({ inputValue3: e.target.value })}
          /> */}
        </div>
      ),
      okText: "是",
      okType: "danger",
      cancelText: "否",
      async onOk() {
        let result = await api.orderUpdateBusiness({
          id: info.id,
          orderCode: info.orderCode,
        });
        if (result == true) {
          message.error("缺少必要信息");
        }
        if (result == false) {
          message.success("转换成功");
        }
        const { pagination } = _t.state;
        _t.fetch({ pagination });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  formRef = React.createRef();

  render() {
    const { dataSource, pagination, loading, userArr, data1, data2 } =
      this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/order")?.children || [];
    const columns = [
      {
        title: "报价单编号",
        dataIndex: "orderCode",
        width: 120,
        // fixed: "left",
        align: "center",
        render: (index, data) => data.orderCode,
      },
      {
        title: "报价单金额",
        dataIndex: "salesAmount",
        width: 80,
        align: "center",
        render: (index, data) => data.salesAmount || "0",
      },
      {
        title: "折扣比",
        dataIndex: "divide",
        width: 70,
        align: "center",
        render: (index, data) => data.divide || "0",
      },
      {
        title: "创建人",
        dataIndex: "nickName",
        width: 100,
        align: "center",
        render: (index, data) => data.nickName,
      },
      {
        title: "状态",
        dataIndex: "orderStatusDesc",
        width: 100,
        align: "center",
        render: (index, data) => data.orderStatusDesc,
      },
      {
        title: "时间",
        dataIndex: "createTime",
        width: 150,
        align: "center",
        render: (index, data) => data.createTime,
      },
      {
        title: "",
        width: "min-content",
        fixed: "right",
        align: "right",
        display: "flex",
        render: (index, data) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {/* {data.enterId == 86 && data.enterId == 86 && data.enterId == 86&& data.enterId == 86&&( */}
              <span>
                {(ruleMenu &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.orderStatus != 3 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      danger
                      size="small"
                      className="mr-1 info submit"
                      onClick={() => this.showModal(data)}
                    >
                      拒绝
                    </Button>
                  )) ||
                  ""}
              </span>
              {/* )} */}
              <span>
                {(ruleMenu &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/del"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      danger
                      size="small"
                      className="mr-1"
                      onClick={() => this.showDeleteConfirm(data)}
                    >
                      删除
                    </Button>
                  )) ||
                  ""}
              </span>
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/detail"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 warn submit"
                    onClick={() => {
                      window.open(
                        "/index/order/details?orderCode=" + data.orderCode
                      );
                    }}
                  >
                    查看
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/edit1"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 info"
                    onClick={() => {
                      // this.props.history.push({
                      //   pathname: "/index/order/quotation",
                      //   search: "orderCode=" + data.orderCode,
                      // });
                      window.open(
                        "/index/order/quotation?orderCode=" + data.orderCode
                      );
                    }}
                  >
                    报价单
                  </Button>
                )) ||
                ""}
              {/* <Button
                type="primary"
                size="small"
                className="mr-1 info"
                onClick={() => {
                  this.showorderUpdateBusiness(data);
                }}
              >
                转为正式合同
              </Button> */}
            </div>
          );
        },
      },
    ];
    const onEditUser = (values) => {
      console.log("Received values of form: ", values);
    };
    return (
      <div>
        <Form
          className="search-box d-flex mb-3"
          style={{ gap: "10px" }}
          name="product-form"
          layout="inline"
          // marginBottom="20px"
          ref={this.formRef}
        >
          <Form.Item
            name="orderCodeLike"
            label="订单编号"
            style={{ width: "240px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item
            name="nickName"
            label="创建人"
            style={{ width: "240px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item name="userId" label="创建人">
            <Select
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: "",
                  label: "全部",
                  key: 0,
                },
                ...userArr.map((e, i) => ({
                  label: e.nickName,
                  value: e.id,
                  key: e.id + 1,
                  props: e,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item
            name="orderStatus"
            label="报价单状态"
            style={{ display: "flex" }}
          >
            <Select
              mode="multiple"
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: "1",
                  label: "待审批",
                  key: 1,
                },
                {
                  value: "2",
                  label: "已通过",
                  key: 2,
                },
                {
                  value: "3",
                  label: "已拒绝",
                  key: 3,
                },
                {
                  value: "4",
                  label: "已取消",
                  key: 4,
                },
                {
                  value: "20",
                  label: "罗总审核中",
                  key: 20,
                },
                {
                  value: "21",
                  label: "jeff审核中",
                  key: 21,
                },
                {
                  value: "6",
                  label: "林总审核中",
                  key: 6,
                },
                {
                  value: "8",
                  label: "技术审核中",
                  key: 8,
                },
                {
                  value: "10",
                  label: "确认下单",
                  key: 10,
                },
                {
                  value: "11",
                  label: "客服主管审核中",
                  key: 11,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="custName"
            label="客户"
            style={{ width: "240px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item name="discount" label="折扣">
            <Select
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: null,
                  label: "全部",
                  key: 0,
                },
                {
                  value: "5折以上",
                  label: "5折以上",
                  key: 1,
                },
                {
                  value: "5折及以下",
                  label: "5折及以下",
                  key: 2,
                },
                {
                  value: "4折以下",
                  label: "4折以下",
                  key: 3,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="date" label="创单日期">
            <RangePicker />
          </Form.Item>
          <Form.Item className="ml-auto d-flex">
            <Button
              type="primary"
              className="mr-1 submit"
              onClick={() => {
                let params = { ...this.formRef.current.getFieldsValue() };
                if (!params.userId) {
                  delete params.userId;
                }
                const { pagination } = this.state;
                pagination.pageNo = 1;
                this.fetch({ pagination, filters: params });
              }}
            >
              查询
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ ...pagination, showSizeChanger: true }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          width="max-content"
          scroll={{ x: "max-content" }}
        />
        {/* 修改用户信息 */}
        <EditUserForm
          visible={this.state.showEdit}
          onCreate={onEditUser}
          onCancel={() => {
            this.modalChange("showEdit", false);
          }}
          chooseUserInfo={this.state.chooseUserInfo}
        />
      </div>
    );
  }
}
export default quote;
