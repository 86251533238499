import React from "react";

import { useState } from "react";
import ReactDOM from "react-dom";
import CryptoJS from "crypto-js";
import { Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Table,
  Row,
  Col,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Checkbox,
  Divider,
  message,
  Cascader,
  InputNumber,
  Space,
  DatePicker,
  Upload,
  Progress,
} from "antd";
import { Modal } from "antd";
import { Steps } from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  SafetyCertificateOutlined,
  CheckOutlined,
  CloseOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
// import moment from "moment";
import api from "../../api";
import { find } from "lodash";
import { color, Model } from "echarts";
import { useForm } from "rc-field-form";
import data from "../../assets/js/data.json";
import { select } from "react-cookies";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

const { Step } = Steps;
const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

const EditUserForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [fileDataSource, setFiletDataSource] = useState([]);
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState(
    chooseUserInfo.pictureList || []
  );
  const [fileListVideo, setFileListVideo] = React.useState(
    chooseUserInfo.video || []
  );
  const [classifyArr, setClassifyArr] = React.useState([]);
  const [classifyConfigListArr, setClassifyConfigListArr] = React.useState([]);
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };
  // setInterval(function(){
  //   window.location.reload();
  // },180000);
  const imageProps = {
    ref: "upload",
    name: "file",
    listType: "picture",
    fileList: fileList,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      res && setFileList([...fileList, res.data]);
      console.log(fileList);
    },
    onRemove: (file) => {
      console.log(file);
      setFileList([...fileList.filter((e) => e != file)]);
      console.log(fileList);
    },
  };

  const videoProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListVideo,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      res && setFileListVideo([res.data]);
    },
    onRemove: (file) => {
      setFileListVideo([]);
    },
  };

  async function getConfigList(params) {
    return await api.categoryGetConfig(params).then((res) => {
      if (res.code === 200) {
        return res.data;
      }
    });
  }

  React.useEffect(() => {
    form.resetFields();
    if (chooseUserInfo.id) {
      api.orderGet({ orderCode: chooseUserInfo.orderCode }).then((res) => {
        // console.log(res);
      });
    }

    if (visible || !visible) {
      form.resetFields();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置产品信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        form.setFieldsValue({
          pictureList: fileList,
          video: fileListVideo[0],
        });
        onCreate(form.getFieldsValue());
        // console.log(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Form.Item name="id">
            <Input type="hidden" value={chooseUserInfo.id} disabled />
          </Form.Item>
          <Form.Item name="title" label="产品标题">
            <Input />
          </Form.Item>
          <Form.Item name="subtitle" label="副标题">
            <Input />
          </Form.Item>
          <Form.Item name="categoryId" label="产品类目">
            <Cascader
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={
                (classifyArr && classifyArr.length && classifyArr) || false
              }
              changeOnSelect={true}
              onChange={(val) => {
                console.log(val);
                form.setFieldsValue({
                  categoryId: val,
                });
                getConfigList({
                  id: val[val.length - 1],
                }).then((res) => {
                  if (res && res.length) {
                    setClassifyConfigListArr(
                      res?.map((e) => ({
                        value: e.id,
                        label: e.title,
                      })) || []
                    );
                  }
                });
              }}
            />
          </Form.Item>
          <Form.Item name="configTemplateId" label="类目配置">
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={
                (classifyConfigListArr &&
                  classifyConfigListArr.length &&
                  classifyConfigListArr) ||
                false
              }
            />
          </Form.Item>
          <Form.Item name="price" label="产品价格">
            <InputNumber max={100} />
          </Form.Item>
          <Form.Item name="isShelves" label="是否上架">
            <Radio.Group>
              <Radio value={true}>上架</Radio>
              <Radio value={false}>下架</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="productDesc" label="产品描述">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item name="pictureList" label="图片">
            <Upload {...imageProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="video" label="视频">
            <Upload {...videoProps}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传视频</div>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
class Contract extends React.Component {
  state = {
    isApprovalModalVisible: false,
    currentData: null, // 保存当前行的数据
    approvalComment: "", // 存储审批意见
    dataSource: [],
    progress: 0, // 当前上传进度
    dataindex: null,
    dataindexOne: null,
    userArr: [],
    isModalOpenLine: false,
    isModalOpen: false,
    isModalOpenOne: false,
    isModalOpenTwo: false,
    selectedSampleType: "",
    pagination: {
      pageNo: 1,
      pageSize: 20,
    },
    showEdit: false,
    showDetail: false,
    loading: false,
    chooseUserInfo: {},
    fileId: "",
    fileListCC: [], // 文件列表
    productLineList: [
      { name: "磁致伸缩类", person: "周航", value: "磁致伸缩类", userId: 322, alternative:0 },
      { name: "浮球连续式", person: "李博文", value: "浮球连续式", userId: 355, alternative:0 },
      { name: "电容类/射频导纳", person: "候乾坤", value: "电容类/射频导纳", userId: 327, alternative:0},
      { name: "微波/阻旋", person: "张存杰", value: "微波/阻旋", userId: 337, alternative:327},
      { name: "浮球类/电极开关", person: "邱盼", value: "浮球类/电极开关", userId: 329, alternative:327},
      { name: "跑偏/测速/磁感应开关", person: "查文斌", value: "跑偏/测速/磁感应开关", userId: 356, alternative:327 },
      { name: "超声波流量计/浮子类流量计/热式流量类", person: "谢亚伟", value: "超声波流量计/浮子类流量计/热式流量类", userId: 330, alternative:0 },
      { name: "涡街流量计类/涡轮流量计类", person: "李星池", value: "涡街流量计类/涡轮流量计类", userId: 323, alternative:0 },
      { name: "齿轮流量计类", person: "吉孟强", value: "齿轮流量计类", userId: 357, alternative:0 },
      { name: "节流元件类/流量开关类", person: "吕瑞峰", value: "节流元件类/流量开关类", userId: 322, alternative:0 },
      { name: "玻璃管玻璃板液位计", person: "何江楠", value: "玻璃管玻璃板液位计", userId: 73, alternative:0 },
      { name: "PH计/电导率/溶氧", person: "陈新明", value: "PH计/电导率/溶氧", userId: 332, alternative:0 },
      { name: "雷达类/超声波物位类", person: "Rustam", value: "雷达类/超声波物位类", userId: 60, alternative:324 },
      { name: "压力类-普通", person: "舒亮铭", value: "压力类-普通", userId: 345, alternative:328 },
      { name: "磁翻板类/电磁流量计类", person: "舒亮铭", value: "磁翻板类/电磁流量计类", userId: 345, alternative:328 },
      { name: "温度类/音叉类", person: "刘振亚", value: "温度类/音叉类", userId: 325, alternative:0 },
      { name: "科里奥利质量流量计", person: "孙翔宇", value: "科里奥利质量流量计", userId: 331, alternative:0 },
      { name: "气动锤/振动器", person: "吴晓峰", value: "气动锤/振动器", userId: 335, alternative:0 },
      { name: "压力开关类/压力表", person: "顾程", value: "压力开关类/压力表", userId: 136, alternative:0 },
      { name: "双金属温度计", person: "顾鹏", value: "双金属温度计", userId: 172, alternative:0 },
      { name: "PFA温度传感器/多点式超声波液位", person: "周海健", value: "PFA温度传感器/多点式超声波液位", userId: 334, alternative:0 },
      { name: "MFC", person: "周海健", value: "MFC", userId: 334, alternative:0},
      { name: "压力类-半导体", person: "周海健", value: "压力类-半导体", userId: 334, alternative:0 },
      { name: "其他", person: "吴晓峰", value: "其他", userId: 335, alternative:0 },
    ],
    updateLine: "",
  };
  handleApprovalModalClose = () => {
    this.setState({ isApprovalModalVisible: false });
  };

  // 处理审批意见改变的函数
  handleApprovalCommentChange = (e) => {
    this.setState({ approvalComment: e.target.value });
  };

  // 处理“通过”按钮点击的函数
  handleApprove = (data) => {
    this.setState({ currentData: data, isApprovalModalVisible: true }); // 显示模态对话框并保存当前数据
  };

  // 提交审批意见的函数
  submitApproval = () => {
    const data = this.state.currentData; // 获取保存的当前行数据
    this.changeStatus1(data, 2, this.state.approvalComment);
    this.setState({ isApprovalModalVisible: false }); // 关闭模态对话框
  };
  myMethod(e) {
    this.fileId = e;
    console.log(this.fileId);
  }
  handleFormSubmit = (values) => {
    // console.log(values.calibrationCertification); // 打印选择的值
    this.setState({ isModalOpenTwo: false });

    // console.log(this.state.e); // 获取到传递进来的e值
    this.changeStatusERPCRM(this.state.e, values.calibrationCertification);
  };

  CCProps = {
    name: "file",
    listType: "picture-card",
    fileList: this.state.fileListCC,
    className: "upload-list-inline",
    customRequest: async ({ file, onSuccess, onError, onProgress }) => {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await api.fileUpload(formData);
        if (res.code === 200) {
          this.setFileUploadLoading(false);
          this.setFileListCC([
            {
              uid: file.uid,
              name: file.name,
              status: "done",
              url: res.data,
            },
          ]);
          onSuccess();
          message.success("上传成功");
          // 更新进度条为100
          onProgress({ percent: 100 });
          const fieldate = `${res.data}`;
          const encryptedFieldate = CryptoJS.AES.encrypt(
            fieldate,
            "my-secret-key"
          ).toString();
          this.setState({ encryptedFieldate });
          const decryptedFieldate = CryptoJS.AES.decrypt(
            encryptedFieldate,
            "my-secret-key"
          ).toString(CryptoJS.enc.Utf8);
          api.orderFileAddresse({
            id: this.fileId.id,
            fileAddress: decryptedFieldate,
          });
        } else {
          message.error(res.message);
          onError();
        }
      } catch (error) {
        message.error("上传失败，请重试！");
        console.log(error);
        onError();
      }
    },
    onRemove: (file) => {
      const fileList = [...this.state.fileListCC];
      const index = fileList.indexOf(file);
      fileList.splice(index, 1);
      this.setFileListCC(fileList);
    },
  };
  //文件上传
  setFileUploadLoading = (loading) => {
    this.setState({ fileUploadLoading: loading });
  };
  //文件上传
  setFileListCC = (fileList) => {
    this.setState({ fileListCC: fileList });
  };
  //文件上传
  fileListCC = this.state.fileListCC;
  //用于产品线样品选择
  //如果有没有选择的产品线，提示几个，如果有不提示直接通过
  showModalLine = (e) => {
    const { productLineList } = this.state;
    const { productLineList: productLineListValue } = e;
    productLineListValue.forEach((eItem) => {
      let matchedItem = null;
      productLineList.forEach((item) => {
        if (item.value === eItem.productLine) {
          matchedItem = item;
        }
      });
      if (matchedItem && eItem.userId === e.enterId) {
        // 触发相应的方法
        this.state.updateLine = eItem;
        this.state.dataindex = e;
        this.setState({ isModalOpenLine: true, e });
      } else {
        // 触发报警提示
        message.warning("未找到符合产品线");
      }
    });
  };
  //
  handleOkLine = () => {
    const { selectedSampleType } = this.state;
    this.state.updateLine.specimen = selectedSampleType;
    // api.orderProductLine({
    //   tbManager: this.state.updateLine,
    //   orderCode: this.state.dataindex.orderCode,
    //   selectedSampleType: selectedSampleType,
    // });
    message.error("请前往生产联络单选择产品阶段");
    this.setState({ isModalOpenLine: false });
  };

  handleCancelLine = () => {
    this.setState({ isModalOpenLine: false });
  };
  //拒绝原因
  showModal = (e) => {
    this.state.dataindex = e;
    this.setState({ isModalOpen: true, e });
  };
  handleOk = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  //上传合同+料号
  showModalOne = (e) => {
    this.state.dataindexOne = e;
    this.setState({ isModalOpenOne: true, e });
  };
  handleOkOne = () => {
    this.setState({ isModalOpenOne: false });
  };
  handleCancelOne = () => {
    this.setState({ isModalOpenOne: false });
  };

  handleProgress = ({ percent }) => {
    this.setState({ progress: percent });
  };
  showModalTwo = (e) => {
    console.log(e);
    this.setState({
      isModalOpenTwo: true,
      e: e,
    });
  };

  handleOkTwo = () => {
    const form = this.form;
    form.validateFields().then((values) => {
      this.setState({ isModalOpenTwo: false });
    });
  };

  handleCancelTwo = () => {
    this.setState({ isModalOpenTwo: false });
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.getUserArr();
    this.fetch({ pagination });
  }
  async getUserArr() {
    let result = await api.getUserList({ pageNo: 1, pageSize: 500 });
    if (result.code == 200) {
      this.setState({
        userArr: result.data.records,
      });
    }
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  async changeStatusERPCRM(e, value) {
    console.log(e);
    const res = await api.orderCRMERP({
      id: e.id,
      userId: e.userId,
      orderCode: e.orderCode,
      pushOrNot: value,
      orderStatus: 2,
    });
  }
  async changeStatus(info, value) {
    const res = await api.orderPutUpdate({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: value,
      approvalComment: this.state.approvalComment,
    });
    if (res.data === true) {
      const { pagination } = this.state;
      this.fetch({ pagination });
      message.success("审核成功", 10);
    } else if (res.data === false) {
      message.error("审核失败", 10);
    } else if ((res.data = "生产联络单未审批")) {
      message.warning("生产联络单未审批", 10);
    } else {
      const { pagination } = this.state;
      this.fetch({ pagination });
      this.showMessageWithBreaks(res.data, info); // 使用自定义的带换行消息显示方式
    }
  }
  async changeStatus1(info, value) {
    const res = await api.orderPutUpdate1({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: value,
      approvalComment: this.state.approvalComment,
    });
  }
  async changeStatusTest(info, value) {
    const res = await api.orderPutUpdateTest({});
  }
  showMessageWithBreaks = (message, info) => {
    Modal.confirm({
      title: "消息",
      content: (
        <div>
          {message.split("\\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </div>
      ),
      okText: "通过",
      cancelText: "暂定",
      onOk() {},
      onCancel() {
        api.Rollback({
          id: info.id,
          userId: info.userId,
          orderCode: info.orderCode,
        });
      },
    });
  };
  //退回到确认下单
  async orderonfirmation(info) {
    const res = await api.orderonfirmation({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: info.orderStatus,
    });
    // if("aa" === "aa"){

    // }
    if (res.data == true) {
      message.success("退回成功");
    } else {
      message.error("退回成功");
    }
  }
  //汪威，pm退回
  async technicalSupport(info) {
    const res = await api.technicalSupport({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: info.orderStatus,
    });
    // if("aa" === "aa"){

    // }
    if (res.data == true) {
      message.success("退回成功");
    } else {
      message.error("退回成功");
    }
  }

  //ding催办
  async reminder(info) {
    const res = await api.reminder({
      id: info.id,
      userId: info.userId,
      orderCode: info.orderCode,
      orderStatus: info.orderStatus,
    });
    // if("aa" === "aa"){

    // }
    if (res.data == true) {
      message.success("推送成功");
    } else {
      message.error("催办失败");
    }
  }
  handleTableChange = (pagination, sorter) => {
    pagination.pageNo = pagination.current;
    const { filters } = this.state;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters: { ...filters },
    });
  };
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let data = await api.orderGetList({
      ...params.pagination,
      ...params.filters,
    });
    let data1 = await api.queryAlltotalALl({
      ...params.pagination,
      ...params.filters,
    });
    if (!data || data.code !== 200) {
      return false;
    }
    if (!data1 || data.code !== 200) {
      return false;
    }
    if (data1.data === null) {
      let orderAmount = 0;
      let salesAmount = 0;
      data1.data = salesAmount;
      data1.data = orderAmount;
    }
    this.setState({
      loading: false,
      dataSource: (data && data.data.records) || [],
      data1: data1.data.orderAmount || [],
      data2: data1.data.salesAmount || [],
      id: (data && data.data.records.id) || [],
      pagination: {
        pageNo: (data && data.data.pageNo) || 1,
        pageSize: (data && data.data.pageSize) || 20,
        total: (data.data && data.data.totalRecords) || 1,
      },
      filters: { ...params.filters },
    });
  };

  showDeleteConfirm = (info) => {
    let _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除订单，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      async onOk() {
        let result = await api.orderDelete(info);
        if (!result || result.code !== 200) {
          message.error("不可删除");
        }
        if (result.code == 200) {
          message.success("删除成功！");
        }
        const { pagination } = _t.state;
        _t.fetch({ pagination });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  formRef = React.createRef();

  render() {
    const { dataSource, pagination, loading, userArr, data1, data2, progress } =
      this.state;
    const currentTime = new Date().getTime();
    const halfHourInMillis = 30 * 60 * 1000;

    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/order")?.children || [];
    const columns = [
      {
        title: "客户名称",
        dataIndex: "custName",
        width: 150,
        align: "center",
        render: (text, record) => {
          // 假设这是您的条件检查
          // const isConditionMet = record.orderProductList.some(product => product.productId === 665);
          // 根据条件动态添加样式类
          // const className = isConditionMet ? "backgroundStyle" : "";
          return text;
        },
      },
      {
        title: "订单编号",
        dataIndex: "orderCode",
        width: 120,
        // fixed: "left",
        align: "center",
        render: (index, data) => data.orderCode,
      },
      {
        title: "订单金额",
        dataIndex: "salesAmount",
        width: 80,
        align: "center",
        render: (index, data) => data.salesAmount || "0",
      },
      {
        title: "折扣比",
        dataIndex: "divide",
        width: 70,
        align: "center",
        render: (index, data) => data.divide || "0",
      },
      {
        title: "创建人",
        dataIndex: "nickName",
        width: 100,
        align: "center",
        render: (index, data) => data.nickName,
      },
      {
        title: "状态",
        dataIndex: "orderStatusDesc",
        width: 100,
        align: "center",
        render: (index, data) => {
          const handleProductLineClick = () => {
            if (data.productLineList && data.productLineList.length > 0) {
              const productLineDetails = data.productLineList
                .map((item, index) => {
                  const statusText = item.status === 1 ? "已审批" : "未审批";
                  return `${item.productLine} - ${item.specimen} - ${statusText}`;
                })
                .join("\n");

              Modal.info({
                title: "产品线状态",
                content: <pre>{productLineDetails}</pre>,
                okText: "确定",
              });
            }
          };

          if (data.orderStatusDesc === "产品线审核中") {
            return (
              <span
                style={{ cursor: "pointer", color: "#3e3e99" }}
                onClick={handleProductLineClick}
              >
                {data.orderStatusDesc}
              </span>
            );
          }
          // render: (index, data) => {
          //   const handleClick = () => {
          //     Modal.confirm({
          //       title: "订单状态详情",
          //       content: data.orderStatusDesc,
          //       okText: "确认",
          //       cancelText: "取消",
          //       onOk: this.showModalOne,
          //       // 将showModalOne方法作为onOk的回调函数
          //     });
          //   };
          //   const handleRemarkClick = () => {
          //     if (
          //       data.remark.includes("合同未回传") &&
          //       data.enterId === data.userId
          //     ) {
          //       handleClick();
          //     }
          //   };
          //   if (data.enterId != 27 && data.enterId === data.userId) {
          //     if (
          //       data.orderStatusDesc === "客服主管审核中" ||
          //       data.orderStatusDesc === "CRM已推送" ||
          //       data.orderStatusDesc === "ERP已推送"
          //     ) {
          //       return (
          //         <span
          //           style={{ cursor: "pointer", color: "#3e3e99" }}
          //           onClick={handleRemarkClick}
          //         >
          //           <div>
          //             <Steps>
          //               {data.crmStatus !== null && data.crmStatus !== "" ? (
          //                 // Existing code for crmStatus not null or empty
          //                 <Step
          //                   title="CRM"
          //                   status={(() => {
          //                     switch (data.crmStatus) {
          //                       case 0:
          //                         return "wait";
          //                       case 1:
          //                         return "error";
          //                       case 2:
          //                         return "finish";
          //                       case 3:
          //                         return "error";
          //                       case 4:
          //                         return "error";
          //                       case 5:
          //                         return "process";
          //                       default:
          //                         return "wait";
          //                     }
          //                   })()}
          //                 />
          //               ) : data.crm !== null && data.crm !== "" ? (
          //                 // Existing code for crm not null or empty
          //                 <Step
          //                   title="CRM"
          //                   status={(() => {
          //                     switch (data.crm) {
          //                       case 1:
          //                         return "wait";
          //                       default:
          //                         return "wait";
          //                     }
          //                   })()}
          //                 />
          //               ) : (
          //                 // New code for crmStatus and crm both null or empty
          //                 <Step title="CRM" status="wait" />
          //               )}

          //               {data.erpStatus !== null && data.erpStatus !== "" ? (
          //                 // Existing code for erpStatus not null or empty
          //                 <Step
          //                   title="ERP"
          //                   status={(() => {
          //                     switch (data.erpStatus) {
          //                       case 0:
          //                         return "wait";
          //                       case 1:
          //                         return "error";
          //                       case 2:
          //                         return "finish";
          //                       case 3:
          //                         return "error";
          //                       case 4:
          //                         return "error";
          //                       case 5:
          //                         return "process";
          //                       default:
          //                         return "wait";
          //                     }
          //                   })()}
          //                 />
          //               ) : data.erp !== null && data.erp !== "" ? (
          //                 // Existing code for erp not null or empty
          //                 <Step
          //                   title="ERP"
          //                   status={(() => {
          //                     switch (data.erp) {
          //                       case 1:
          //                         return "wait";
          //                       default:
          //                         return "wait";
          //                     }
          //                   })()}
          //                 />
          //               ) : (
          //                 // New code for erpStatus and erp both null or empty
          //                 <Step title="ERP" status="wait" />
          //               )}
          //             </Steps>
          //           </div>
          //         </span>
          //       );
          //     } else {
          //       return (
          //         <span style={{ cursor: "pointer" }} onClick={handleRemarkClick}>
          //           {data.orderStatusDesc}
          //         </span>
          //       );
          //     }
          //   } else if (
          //     data.enterId != data.userId &&
          //     data.orderStatusDesc === "CRM已推送" &&
          //     data.enterId != 27
          //   ) {
          //     return (
          //       <span style={{ cursor: "pointer" }} onClick={handleRemarkClick}>
          //         客服主管审核中
          //       </span>
          //     );
          //   }
          //   if (data.orderStatusDesc === 14) {
          //     return (
          //       <span
          //         style={{ cursor: "pointer", color: "#3e3e99" }}
          //         onClick={handleProductLineClick}
          //       >
          //         {data.orderStatusDesc}
          //       </span>
          //     );
          //   } else if (
          //     data.enterId != data.userId &&
          //     data.orderStatusDesc === "ERP已推送" &&
          //     data.enterId != 27
          //   ) {
          //     return (
          //       <span style={{ cursor: "pointer" }} onClick={handleRemarkClick}>
          //         客服主管审核中
          //       </span>
          //     );
          //   } else {
          //     return (
          //       <span style={{ cursor: "pointer" }} onClick={handleRemarkClick}>
          //         {data.orderStatusDesc}
          //       </span>
          //     );
          //   }

          // },
          return data.orderStatusDesc;
        },
      },

      {
        title: "业务员",
        dataIndex: "contacts1",
        width: 100,
        align: "center",
        render: (index, data) => data.contacts1 || "-",
      },
      {
        title: "是否新签",
        dataIndex: "orderCode",
        width: 120,
        // fixed: "left",
        align: "center",
        render: (index, data) => {
          const value = data.whetherToSignNew;
          return value ? value : <div style={{ display: "none" }}>-</div>;
        },
      },
      {
        title: "时间",
        dataIndex: "createTime",
        width: 150,
        align: "center",
        render: (index, data) => data.createTime,
      },
      {
        title: "技术审批节点",
        dataIndex: "technicalSupportApproval",
        width: 150,
        align: "center",
        render: (index, data) => data.technicalSupportApproval,
      },
      {
        title: "产品经理审批节点",
        dataIndex: "pmApprove",
        width: 150,
        align: "center",
        render: (index, data) => data.pmApprove,
      },
      {
        title: "主管审批节点",
        dataIndex: "approvalBySupervisor",
        width: 150,
        align: "center",
        render: (index, data) => data.approvalBySupervisor,
      },
      {
        title: "罗总审批节点",
        dataIndex: "approvalByManager",
        width: 150,
        align: "center",
        render: (index, data) => data.approvalByManager,
      },
      {
        title: "订单原价",
        dataIndex: "productAmount",
        width: 80,
        align: "center",
        render: (index, data) => data.productAmount || "0",
      },
      {
        title: "",
        width: "min-content",
        fixed: "right",
        align: "right",
        display: "flex",
        render: (index, data) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {/* {data.enterId == 86 && data.enterId == 86 && data.enterId == 86&& data.enterId == 86&&( */}
              <span>
                {ruleMenu &&
                  Date.parse(data.createTime) &&
                  // currentTime - data.createTime.getTime() >
                  //   halfHourInMillis &&
                  (data.technicalSupportApproval == null ||
                    data.approvalBySupervisor == null ||
                    data.approvalByManager == null ||
                    data.finalApproval == null) &&
                  (data.orderStatus == 8 ||
                    data.orderStatus == 1 ||
                    data.orderStatus == 5 ||
                    data.orderStatus == 6) &&
                  data.enterId == data.userId &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.reminder(data)}
                    >
                      Ding
                    </Button>
                  )}

                {(ruleMenu &&
                  (data.enterId == 86) |
                    (data.enterId == 87) |
                    (data.enterId == 171) |
                    (data.enterId == 89) |
                    (data.enterId == 84) |
                    (data.enterId == 108) |
                    (data.enterId == 73) |
                    (data.enterId == 136) |
                    (data.enterId == 246) |
                    (data.enterId == 172) |
                    (data.enterId == 266) |
                    (data.enterId == 276) |
                    (data.enterId == 287) |
                    (data.enterId == 20) &&
                  data.orderStatus == 8 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {/* {(ruleMenu &&
                  (data.enterId == 322) |
                    (data.enterId == 323) |
                    (data.enterId == 324) |
                    (data.enterId == 325) |
                    (data.enterId == 326) |
                    (data.enterId == 327) |
                    (data.enterId == 328) |
                    (data.enterId == 329) |
                    (data.enterId == 330) |
                    (data.enterId == 331) |
                    (data.enterId == 332) |
                    (data.enterId == 333) |
                    (data.enterId == 334) |
                    (data.enterId == 335) |
                    (data.enterId == 337) |
                    (data.enterId == 87) |
                    (data.enterId == 171) |
                    (data.enterId == 84) |
                    (data.enterId == 73) |
                    (data.enterId == 89) |
                    (data.enterId == 86) |
                    (data.enterId == 172) |
                    (data.enterId == 136) &&
                  data.orderStatus == 14 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.showModalLine(data)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""} */}

                {(ruleMenu,
                [
                  "322",
                  "323",
                  "324",
                  "325",
                  "326",
                  "327",
                  "328",
                  "329",
                  "330",
                  "331",
                  "332",
                  "333",
                  "334",
                  "335",
                  "337",
                  "87",
                  "171",
                  "84",
                  "73",
                  "89",
                  "86",
                  "172",
                  "136",
                ].includes(data.enterId) &&
                  data.orderStatus === 14 &&
                  // 检查productLineList中的条件
                  (data.productLineList.some((item) => {
                    if (item) {
                      console.log("检查item:", item);
                      // 判断userId是否相同
                      if (item.userId === data.enterId) {
                        // 检查通过条件
                        if (item.status === 0) {
                          return true; // 显示“通过”按钮
                        }
                      }
                    }
                    return false; // 如果不满足条件，返回false
                  }) ? (
                    // 显示“通过”按钮
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        this.showModalLine(data);
                      }}
                    >
                      通过
                    </Button>
                  ) : // 检查是否有已审批的状态
                  data.productLineList.some((item) => {
                      if (item) {
                        return (
                          item.userId === data.enterId &&
                          item.status === 1 &&
                          item.specimen !== null
                        );
                      }
                      return false;
                    }) ? (
                    <Button
                      type="default"
                      size="small"
                      className="mr-1 submit"
                      disabled
                    >
                      已审批
                    </Button>
                  ) : (
                    (console.log("显示无需审批按钮"),
                    (
                      <Button
                        type="default"
                        size="small"
                        className="mr-1 submit"
                      >
                        无需审批
                      </Button>
                    ))
                  ))) || ""}

                {(ruleMenu &&
                  (data.enterId == 67) |
                    (data.enterId == 68) |
                    (data.enterId == 22) |
                    (data.enterId == 370) |
                    (data.enterId == 245) |
                    (data.enterId == 214) |
                    (data.enterId == 24) &&
                  data.orderStatus == 1 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.enterId == 22 &&
                  data.orderStatus == 21 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                 {(ruleMenu &&
                  data.enterId == 68 &&
                  data.orderStatus == 20 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.enterId == 41 &&
                  data.orderStatus == 6 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => this.changeStatus(data, 2)}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.enterId == data.userId &&
                  data.orderStatus == 10 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        const isValid = index.orderProductList.every(
                          (item) => item.partNo !== null && item.partNo !== ""
                        );
                        if (isValid) {
                          this.showModalOne(data);
                          this.myMethod(data);
                        } else {
                          message.error("请完善产品料号");
                        }
                      }}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  (data.enterId == 27) | (data.enterId == 28) | (data.enterId == 134) |(data.enterId == 61) &&
                  data.orderStatus == 11 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        if (index.orderProductList[0].partNo != null) {
                          // console.log(data);
                          this.showModalTwo(data);
                          // this.changeStatus(data, 2);
                          // this.myMethod(data);
                        } else {
                          message.error("请完善产品料号");
                        }
                      }}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  (data.enterId == 27) | (data.enterId == 28) | (data.enterId == 134) |(data.enterId == 61) &&
                  data.orderStatus == 13 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        if (index.orderProductList[0].partNo != null) {
                          // console.log(data);
                          this.showModalTwo(data);
                          // this.changeStatus(data, 2);
                          // this.myMethod(data);
                        } else {
                          message.error("请完善产品料号");
                        }
                      }}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  (data.enterId == 27) | (data.enterId == 28) | (data.enterId == 134) |(data.enterId == 61) &&
                  data.orderStatus == 12 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        if (index.orderProductList[0].partNo != null) {
                          // console.log(data);
                          this.showModalTwo(data);
                          // this.changeStatus(data, 2);
                          // this.myMethod(data);
                        } else {
                          message.error("请完善产品料号");
                        }
                      }}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.enterId == 350 &&
                  data.orderStatus == 15 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      size="small"
                      className="mr-1 submit"
                      onClick={() => {
                        this.handleApprove(data);
                      }}
                    >
                      通过
                    </Button>
                  )) ||
                  ""}
                {(ruleMenu &&
                  data.orderStatus != 3 &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/order/contract/audit"
                  ) >= 0 && (
                    <Button
                      type="primary"
                      danger
                      size="small"
                      className="mr-1 info submit"
                      onClick={() => this.showModal(data)}
                    >
                      拒绝
                    </Button>
                  )) ||
                  ""}
              </span>
              {/* )} */}
              {data.userId == data.enterId && data.orderStatus == 8 && (
                <span>
                  {(ruleMenu &&
                    ruleMenu.findIndex(
                      (e) => e.path == "/index/order/contract/del"
                    ) >= 0 && (
                      <Button
                        type="primary"
                        danger
                        size="small"
                        className="mr-1"
                        onClick={() => this.showDeleteConfirm(data)}
                      >
                        删除
                      </Button>
                    )) ||
                    ""}
                </span>
              )}
              {(ruleMenu &&
                (data.enterId == 27) |
                  (data.enterId == 28) |
                  (data.enterId == 134) |
                  (data.enterId == 61) &&
                (data.orderStatus == 11) |
                  (data.orderStatus == 12) |
                  (data.orderStatus == 13) && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 submit"
                    onClick={() => {
                      this.orderonfirmation(data);
                    }}
                  >
                    退回
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                (data.enterId == 350) |
                  (data.enterId == 322) |
                  (data.enterId == 323) &&
                (data.enterId == 324) |
                  (data.enterId == 326) |
                  (data.enterId == 327) |
                  (data.enterId == 328) |
                  (data.enterId == 329) |
                  (data.enterId == 330) |
                  (data.enterId == 331) |
                  (data.enterId == 333) |
                  (data.enterId == 334) |
                  (data.enterId == 87) |
                  (data.enterId == 171) |
                  (data.enterId == 84) |
                  (data.enterId == 73) |
                  (data.enterId == 89) |
                  (data.enterId == 86) |
                  (data.enterId == 172) |
                  (data.enterId == 136) |
                  (data.enterId == 332) |
                  (data.enterId == 335) |
                  (data.enterId == 345) |
                  (data.orderStatus == 14) |
                  (data.orderStatus == 15) && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 submit"
                    onClick={() => {
                      this.technicalSupport(data);
                    }}
                  >
                    退回
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/detail"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 warn submit"
                    onClick={() => {
                      // let btn = document.createElement("a");
                      // btn.target = "_blank";
                      // this.props.history.push({
                      //   pathname: "/index/order/details",
                      //   search: "orderCode=" + data.orderCode,
                      // });
                      window.open(
                        "/index/order/details?orderCode=" + data.orderCode
                      );
                    }}
                  >
                    查看
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/edit1"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 info"
                    onClick={() => {
                      // this.props.history.push({
                      //   pathname: "/index/order/quotation",
                      //   search: "orderCode=" + data.orderCode,
                      // });
                      window.open(
                        "/index/order/quotation?orderCode=" + data.orderCode
                      );
                    }}
                  >
                    报价单
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/edit2"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 info"
                    onClick={() => {
                      // this.props.history.push({
                      //   pathname: "/index/order/electronicContract",
                      //   search: "orderCode=" + data.orderCode,
                      // });
                      window.open(
                        "/index/order/electronicContract?orderCode=" +
                          data.orderCode
                      );
                    }}
                  >
                    合同
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/edit3"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 info submit"
                    onClick={() => {
                      // this.props.history.push({
                      //   pathname: "/index/order/contactList",
                      //   search: "orderCode=" + data.orderCode,
                      // });
                      window.open(
                        "/index/order/contactList?orderCode=" + data.orderCode
                      );
                    }}
                  >
                    生产单
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                (data.enterId == 43) | (data.enterId == 60) &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/order/contract/audit"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 submit"
                    onClick={() => {
                      this.changeStatusTest(data, 2);
                    }}
                  >
                    测试OA
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];
    const onEditUser = (values) => {
      console.log("Received values of form: ", values);
    };
    return (
      <div>
        <Form
          className="search-box d-flex mb-3"
          style={{ gap: "10px" }}
          name="product-form"
          layout="inline"
          // marginBottom="20px"
          ref={this.formRef}
        >
          <Form.Item
            name="orderCodeLike"
            label="订单编号"
            style={{ width: "280px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item
            name="nickName"
            label="创建人"
            style={{ width: "280px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item name="userId" label="创建人">
            <Select
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: "",
                  label: "全部",
                  key: 0,
                },
                ...userArr.map((e, i) => ({
                  label: e.nickName,
                  value: e.id,
                  key: e.id + 1,
                  props: e,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item
            name="orderStatus"
            label="订单状态"
            style={{ display: "flex" }}
          >
            <Select
              mode="multiple"
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: "1",
                  label: "待审批",
                  key: 1,
                },
                {
                  value: "2",
                  label: "已通过",
                  key: 2,
                },
                {
                  value: "3",
                  label: "已拒绝",
                  key: 3,
                },
                {
                  value: "4",
                  label: "已取消",
                  key: 4,
                },
                {
                  value: "20",
                  label: "罗总审核中",
                  key: 20,
                },
                {
                  value: "21",
                  label: "jeff审核中",
                  key: 21,
                },
                {
                  value: "8",
                  label: "技术审核中",
                  key: 8,
                },
                {
                  value: "10",
                  label: "确认下单",
                  key: 10,
                },
                {
                  value: "11",
                  label: "客服主管审核中",
                  key: 11,
                },
                {
                  value: "14",
                  label: "产品经理审核中",
                  key: 14,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="department" label="部门" style={{ display: "flex" }}>
            <Select
              style={{ width: "170px", label: "70px", gap: "20px" }}
              options={[
                {
                  value: null,
                  label: "全部",
                  key: 0,
                },
                {
                  value: "销售一部",
                  label: "销售一部",
                  key: 1,
                },
                {
                  value: "客服部",
                  label: "客服部",
                  key: 2,
                },
                {
                  value: "销售二部",
                  label: "销售二部",
                  key: 3,
                },
                {
                  value: "销售三部",
                  label: "销售三部",
                  key: 3,
                },
                {
                  value: "海外事业部",
                  label: "海外事业部",
                  key: 4,
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="productLine"
            label="产品线筛选："
            style={{ display: "flex", alignItems: "center", gap: "10px" }} // 增加间距
          >
            <Select
              mode="multiple"
              style={{ width: "200px" }} // 增加宽度
              options={[
                {
                  value: null,
                  label: "全部",
                  key: 0,
                },
                {
                  value: "磁致伸缩类",
                  label: "磁致伸缩类",
                  key: 1,
                },
                {
                  value: "浮球连续式",
                  label: "浮球连续式",
                  key: 2,
                },
                {
                  value: "电容类/射频导纳",
                  label: "电容类/射频导纳",
                  key: 3,
                },
                {
                  value: "微波/阻旋",
                  label: "微波/阻旋",
                  key: 4,
                },
                {
                  value: "浮球类/电极开关",
                  label: "浮球类/电极开关",
                  key: 5,
                },
                {
                  value: "跑偏/测速/磁感应开关",
                  label: "跑偏/测速/磁感应开关",
                  key: 6,
                },
                {
                  value: "超声波流量计/浮子类流量计/热式流量类",
                  label: "超声波流量计/浮子类流量计/热式流量类",
                  key: 7,
                },
                {
                  value: "涡街流量计类/涡轮流量计类",
                  label: "涡街流量计类/涡轮流量计类",
                  key: 8,
                },
                {
                  value: "齿轮流量计类",
                  label: "齿轮流量计类",
                  key: 9,
                },
                {
                  value: "玻璃管玻璃板液位计",
                  label: "玻璃管玻璃板液位计",
                  key: 10,
                },
                {
                  value: "节流元件类/流量开关类",
                  label: "节流元件类/流量开关类",
                  key: 10,
                },
                {
                  value: "PH计/电导率/溶氧",
                  label: "PH计/电导率/溶氧",
                  key: 11,
                },
                {
                  value: "雷达类/超声波物位类",
                  label: "雷达类/超声波物位类",
                  key: 12,
                },
                {
                  value: "压力类-普通",
                  label: "压力类-普通",
                  key: 13,
                },
                {
                  value: "磁翻板类/电磁流量计类",
                  label: "磁翻板类/电磁流量计类",
                  key: 14,
                },
                {
                  value: "温度类/音叉类",
                  label: "温度类/音叉类",
                  key: 15,
                },
                {
                  value: "科里奥利质量流量计",
                  label: "科里奥利质量流量计",
                  key: 16,
                },
                {
                  value: "气动锤/振动器",
                  label: "气动锤/振动器",
                  key: 17,
                },
                {
                  value: "压力开关类/压力表",
                  label: "压力开关类/压力表",
                  key: 18,
                },
                {
                  value: "双金属温度计",
                  label: "双金属温度计",
                  key: 19,
                },
                {
                  value: "PFA温度传感器/多点式超声波液位",
                  label: "PFA温度传感器/多点式超声波液位",
                  key: 20,
                },
                {
                  value: "MFC",
                  label: "MFC",
                  key: 21,
                },
                {
                  value: "压力类-半导体",
                  label: "压力类-半导体",
                  key: 22,
                },
                {
                  value: "其他",
                  label: "其他",
                  key: 23,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="contacts1"
            label="业务员"
            style={{ width: "280px", gap: "20px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item
            name="custName"
            label="客户"
            style={{ width: "280px", }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item
            name="productModel"
            label="型号"
            style={{ width: "240px" }}
          >
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item name="discount" label="折扣">
            <Select
              style={{ width: "170px", gap: "20px" }}
              options={[
                {
                  value: null,
                  label: "全部",
                  key: 0,
                },
                {
                  value: "5折以上",
                  label: "5折以上",
                  key: 1,
                },
                {
                  value: "5折及以下",
                  label: "5折及以下",
                  key: 2,
                },
                {
                  value: "4折以下",
                  label: "4折以下",
                  key: 3,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="date" label="创单日期">
            <RangePicker />
          </Form.Item>

          <Form.Item className="ml-auto d-flex">
            <Button
              type="primary"
              className="mr-1 submit"
              onClick={() => {
                let params = { ...this.formRef.current.getFieldsValue() };
                if (!params.userId) {
                  delete params.userId;
                }
                const { pagination } = this.state;
                pagination.pageNo = 1;
                this.fetch({ pagination, filters: params });
              }}
            >
              查询
            </Button>
            {/* {(ruleMenu &&
              ruleMenu.findIndex((e) => e.path == "/index/overview/export6") >=
                0 && (
                <Button
                  type="ghost"
                  onClick={async () => {
                    let result = await api.categoryOrderStatisticByDateExport({
                      timeFrom: moment(defaultDateTime5[0]).format(
                        "YYYY-MM-DD"
                      ),
                      timeTo: moment(defaultDateTime5[1]).format("YYYY-MM-DD"),
                    });

                    message.success("导出成功！");
                    let url = window.URL.createObjectURL(new Blob([result]));
                    // 创建A标签
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    // 设置的下载文件文件名
                    const fileName = `产品分类销售额.xlsx`;
                    // 触发点击方法
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                  }}
                >
                  导出
                </Button>
              )) ||
              ""} */}
          </Form.Item>
        </Form>
        <div className="pc-btn">
          <Form>
            <div>
              <Row className="basicInfo" gutter={30}>
                <div className="basicInfo-item" style={{ marginRight: "20px" }}>
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      flex: "0 1",
                      justifyContent: "space-between",
                      padding: "10px",
                      border: "1px solid #eee",
                      borderRadius: "10px",
                      marginBottom: "20px",
                      minWidth: "min-content",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <ShoppingCartOutlined
                        style={{ fontSize: 20, color: "rgb(15,178,142)" }}
                      />
                    </div>
                    <p
                      style={{
                        color: "#9d9d9d",
                        fontSize: "16px",
                        marginBottom: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      实际销售金额
                    </p>
                    <div style={{ textAlign: "right" }}>
                      <div style={{ fontSize: "24px", fontWeight: "bolder" }}>
                        {data2 || "-"}
                        {/* {12345} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basicInfo-item" style={{ marginRight: "20px" }}>
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      flex: "0 1",
                      justifyContent: "space-between",
                      padding: "10px",
                      border: "1px solid #eee",
                      borderRadius: "10px",
                      marginBottom: "20px",
                      minWidth: "min-content",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <ShoppingCartOutlined
                        style={{ fontSize: 20, color: "rgb(201,54,54)" }}
                      />
                    </div>
                    <p
                      style={{
                        color: "#9d9d9d",
                        fontSize: "16px",
                        marginBottom: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      订单原价总额
                    </p>
                    <div style={{ textAlign: "right" }}>
                      <div style={{ fontSize: "24px", fontWeight: "bolder" }}>
                        {data1 || "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basicInfo-item" style={{ marginRight: "20px" }}>
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      flex: "0 1",
                      justifyContent: "space-between",
                      padding: "10px",
                      border: "1px solid #eee",
                      borderRadius: "10px",
                      marginBottom: "20px",
                      minWidth: "min-content",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <PieChartOutlined
                        style={{ fontSize: 20, color: "rgb(0,128,251)" }}
                      />
                    </div>
                    <p
                      style={{
                        color: "#9d9d9d",
                        fontSize: "16px",
                        marginBottom: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      折扣比
                    </p>
                    <div style={{ textAlign: "right" }}>
                      <div style={{ fontSize: "24px", fontWeight: "bolder" }}>
                        {(data2 / data1).toFixed(4) || "-"}
                        {/* {12345678} */}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Form>
          <Modal
            title="拒绝原因"
            visible={this.state.isModalOpen}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            maskClosable={false}
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <Input.TextArea
                name="releaseCause"
                placeholder="填写内容即代表拒绝"
                onBlur={async (e) => {
                  let aa = await api.orderReleaseCause({
                    id: this.state.dataindex.id,
                    orderCode: this.state.dataindex.orderCode,
                    releaseCause: e.target.value,
                  });
                  if (aa.data === true) {
                    this.componentDidMount();
                  }
                }}
              />
            </Form.Item>
          </Modal>
          <Modal
            title="选择样品类别"
            visible={this.state.isModalOpenLine}
            onOk={this.handleOkLine}
            onCancel={this.handleCancelLine}
          >
            {this.state.e?.productLineList.map((line, index) => {
              if (!line || !this.state.e || !this.state.e.enterId) {
                return null; // 如果有任何一个变量不存在或为空，则返回null
              }

              if (line.userId === this.state.e.enterId) {
                return (
                  <div key={index}>
                    <p>
                      产品名称:{" "}
                      {this.state.e.orderProductList[index]?.productTitle}
                      <br />
                      型号: {this.state.e.orderProductList[index]?.productModel}
                    </p>
                    <Select
                      placeholder={`请选择样品类别 ${index + 1}`}
                      value={this.state[`selectedSampleType${index}`]}
                      onChange={(value) =>
                        this.setState({ [`selectedSampleType${index}`]: value })
                      }
                    >
                      <Option value="A样">A样</Option>
                      <Option value="B样">B样</Option>
                      <Option value="C样">C样</Option>
                      <Option value="D样">D样</Option>
                    </Select>
                  </div>
                );
              }

              return null; // 不满足条件时返回null
            })}
          </Modal>
          <Modal
            title="补充资料"
            visible={this.state.isModalOpenOne}
            onOk={this.handleOkOne}
            onCancel={this.handleCancelOne}
            maskClosable={false}
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="large"
              ></Space>
              <Form.Item name="calibrationCertification" label="上传合同">
                <Upload
                  maxCount={3}
                  onProgress={this.handleProgress}
                  {...this.CCProps}
                  listType="picture-card"
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>上传合同</div>
                  </div>
                </Upload>
                <Progress percent={this.state.progress} />
              </Form.Item>
            </Form.Item>
          </Modal>
          <Modal
            title="选择推送"
            visible={this.state.isModalOpenTwo}
            onCancel={this.handleCancelTwo}
            maskClosable={false}
            footer={null}
            destroyOnClose={true}
            // 关闭 Modal 后销毁子元素
          >
            <Form
              onFinish={this.handleFormSubmit}
              initialValues={{ calibrationCertification: "推送CRM" }}
              form={this.form}
              // 绑定表单实例对象
            >
              <Form.Item name="calibrationCertification" label="选择推送">
                <Radio.Group>
                  <Radio
                    value="推送CRM"
                    disabled={this.state.e && this.state.e.crm !== null}
                  >
                    推送CRM
                  </Radio>
                  <Radio
                    value="推送ERP"
                    disabled={this.state.e && this.state.e.erp !== null}
                  >
                    推送ERP
                  </Radio>
                  <Radio value="推送ERP+推送CRM" defaultChecked>
                    推送ERP+推送CRM
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="审批意见"
            visible={this.state.isApprovalModalVisible}
            onCancel={this.handleApprovalModalClose}
            footer={null}
          >
            <Form.Item
              name="approvalComment"
              label="审批意见"
              rules={[{ required: true, message: "审批意见为必填项" }]}
            >
              <Input.TextArea
                placeholder="请输入审批意见"
                value={this.state.approvalComment}
                onChange={this.handleApprovalCommentChange}
              />
            </Form.Item>
            <Button
              type="primary"
              onClick={this.submitApproval} // 确保这里调用 submitApproval 方法
            >
              提交
            </Button>
          </Modal>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ ...pagination, showSizeChanger: true }}
          loading={loading}
          data1={data1}
          data2={data2}
          onChange={this.handleTableChange}
          size="small"
          sticky
          width="max-content"
          scroll={{ x: "max-content" }}
        />
        {/* 修改用户信息 */}
        <EditUserForm
          visible={this.state.showEdit}
          onCreate={onEditUser}
          onCancel={() => {
            this.modalChange("showEdit", false);
          }}
          chooseUserInfo={this.state.chooseUserInfo}
        />
      </div>
    );
  }
}
export default Contract;
