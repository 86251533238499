import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Dropdown,
  Radio,
  Modal,
  Select,
  AutoComplete,
} from "antd";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import RcUeditor from "react-ueditor-wrap";
import api from "../../api";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import utils from "../../assets/js/public.js";
import "./tabel.less";

const { Option } = Select;
const digitUppercase = (n) => {
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  var head = n < 0 ? "欠" : "";
  n = Math.abs(n);
  var s = "";
  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
};

const ElectronicContract = () => {
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setquotationInfo] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("0.00");
  const [ueditorContent, setUeditorContent] = useState();
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [exportType, setExportType] = useState(null);
  const showExportModal = (type) => {
    if (type === "111") {
      // 如果是月结采购订单，则不显示模态框
      api.contractPurchaseExport({ orderCode: orderCode }).then((res) => {
        if (res.code === 200) {
          window.open(res.data);
        }
      });
    } else {
      setExportType(type);
      setExportModalVisible(true);
    }
  };
  const fetchDeliveryOptions = async (searchText) => {
    try {
      const res = await api.getDeliveryOptions({ searchText });
      if (res.code === 200) {
        setDeliveryOptions(res.data);
      }
    } catch (error) {
      console.error("Error fetching delivery options:", error);
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    // 设置paymentType的初始值为第一个Radio的值
    form.setFieldsValue({ paymentType: "percentageDelivered" });
  }, [form]);

  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 80,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "产品名称",
      dataIndex: "",
      width: 200,
      render: (text, data, index) => {
        return data.productTitle || "-";
      },
    },
    {
      title: "品种规格",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <div>
            型号：{data.productModel}
            {(data.productConfig &&
              JSON.parse(data.productConfig).map((e) => {
                return (
                  <div>
                    {e.label}:{e.value}
                  </div>
                );
              })) ||
              "-"}
          </div>
        );
      },
    },
    {
      title: "数量",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (data.productNumber && data.productNumber + "PCS") || "-";
      },
    },
    {
      title: "单价（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (data.salesAmount && data.salesAmount.toFixed(2)) || "-";
      },
    },
    {
      title: "总金额（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (
          (data.salesAmount &&
            data.productNumber &&
            (data.salesAmount * data.productNumber).toFixed(2)) ||
          "-"
        );
      },
    },
  ];

  const editorConfig = {
    toolbars: [
      [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "forecolor",
        "backcolor",
        "justifyleft",
        "justifycenter",
        "justifyright",
        "|",
        "insertunorderedlist",
        "insertorderedlist",
        "blockquote",
      ],
    ],
    autoClearinitialContent: false,
    autoFloatEnabled: false,
    autoHeightEnabled: false,
    focus: false,
    initialFrameWidth: "100%", // 初始化编辑器宽度
    initialFrameHeight: 300,
    maximumWords: 100000,
    isChange: false,
  };

  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    console.log(orderCode);
    let res = await api.orderGetContract({ orderCode });
    // console.log(res);
    let contractTerms;
    if (res.data.contractTerms) {
      contractTerms = res.data.contractTerms;
      // delete res.data.contractTerms;
    } else {
      delete res.data.contractTerms;
    }
    form.setFieldsValue({
      ...res.data,
      signTime: (res.data.signTime && moment(res.data.signTime)) || "",
    });

    if (contractTerms) {
      setUeditorContent(contractTerms);
    }
    let totalPrice = 0;
    [...res.data.orderProductList].map((e) => {
      totalPrice += e.salesAmount * e.productNumber;
    });
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource([...res.data.orderProductList]);
  };

  useEffect(() => {
    getQuotationInfo();
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let params = {
      ...values,
      id: quotationInfo.id,
      orderCode,
      signTime:
        (values?.signTime &&
          moment(values?.signTime).format("YYYY-MM-DD HH:mm:ss")) ||
        "",
    };
    let res = await api.orderPutContractUpdate(params);

    if (res.code == 200) {
      api
        .contractExport({
          orderCode: qs.parse(window.location.search.split("?")[1]).orderCode,
        })
        .then((res) => {
          if (res.code == 200) {
            let btn = document.createElement("a");
            btn.download = `订单编号-${
              qs.parse(window.location.search.split("?")[1]).orderCode
            }-电子合同.pdf`;
            btn.href = res.data;
            btn.target = "_blank";
            btn.click();
            btn = null;
          }
        });
      return message.success("保存成功,正在生成单据");
    }
    message.error(res.message);
    console.log(res);
    // console.log(res)
  };

  const hanldeChage = (value, type) => {
    // console.log(value, type);
    form.setFieldsValue({
      [type]: value,
    });
    console.log(form.getFieldsValue());
  };
  const [orderCode, setOrderCode] = useState("");

  useEffect(() => {
    const parsedQs = qs.parse(window.location.search.split("?")[1]);
    setOrderCode(parsedQs.orderCode);
  }, []);

  // const handleExportModalOk = () => {
  //   const exportData = {
  //     nonMonthly: {
  //       prePaymentDays: form.getFieldValue("prePaymentDays"),
  //       paymentPercentage: form.getFieldValue("paymentPercentage"),
  //       deliveryDay: form.getFieldValue("deliveryDay"),
  //       percentageDelivered: form.getFieldValue("percentageDelivered"),
  //       percentageDeliveredDays: form.getFieldValue("percentageDeliveredDays"),
  //       percentageShippedDays: form.getFieldValue("percentageShippedDays"),
  //       contactPersonA: form.getFieldValue("contactPersonA"),
  //       contactPersonB: form.getFieldValue("contactPersonB"),
  //       mailingByPartyA: form.getFieldValue("mailingByPartyA"),
  //       mailingByPartyB: form.getFieldValue("mailingByPartyB"),
  //       partyAddressA: form.getFieldValue("partyAddressA"),
  //       partyAddressB: form.getFieldValue("partyAddressB"),
  //       validYear: form.getFieldValue("validYear"),
  //       accessoryEntry: form.getFieldValue("accessoryEntry"),
  //       numberMonths: form.getFieldValue("numberMonths"),
  //       deliveryPlace: form.getFieldValue("deliveryPlace"),
  //       takeDelivery: form.getFieldValue("takeDelivery"),
  //       contactInformation: form.getFieldValue("contactInformation"),
  //       emailAddress: form.getFieldValue("emailAddress"),
  //       // ... 其他非月结字段
  //     },
  //     monthlyAgreement: {
  //       monthEndDays: form.getFieldValue("monthEndDays"),
  //       invoiceDate: form.getFieldValue("invoiceDate"),
  //       contactPersonA: form.getFieldValue("contactPersonA"),
  //       contactPersonB: form.getFieldValue("contactPersonB"),
  //       mailingByPartyA: form.getFieldValue("mailingByPartyA"),
  //       mailingByPartyB: form.getFieldValue("mailingByPartyB"),
  //       partyAddressA: form.getFieldValue("partyAddressA"),
  //       partyAddressB: form.getFieldValue("partyAddressB"),
  //       validYear: form.getFieldValue("validYear"),
  //       numberMonths: form.getFieldValue("numberMonths"),
  //       // ... 其他月结框架协议字段
  //     },
  //   }[exportType];

  //   // 根据exportType调用不同的API
  //   switch (exportType) {
  //     case "non-monthly":
  //       console.log("Export Data:", exportData); // 打印 exportData 查看内容
  //       api
  //         .contractNonPurchaseExport({ orderCode, ...exportData })
  //         .then((res) => {
  //           if (res.code === 200) {
  //             window.open(res.data);
  //           }
  //         });
  //       break;
  //     case "monthly-agreement":
  //       console.log("Export Data:", exportData); // 打印 exportData 查看内容
  //       api.contractFrameExport({ orderCode, ...exportData }).then((res) => {
  //         if (res.code === 200) {
  //           window.open(res.data);
  //         }
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  //   setExportModalVisible(false);
  // };
  const handleExportModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        // 表单验证通过后，values 包含了表单的数据
        console.log("Export Data:", values); // 打印 exportData 查看内容
        // 根据exportType调用不同的API
        switch (exportType) {
          case "monthly":
            api.contractPurchaseExport({ orderCode, ...values }).then((res) => {
              if (res.code === 200) {
                window.open(res.data);
              }
            });
            break;
          case "non-monthly":
            api
              .contractNonPurchaseExport({ orderCode, ...values })
              .then((res) => {
                if (res.code === 200) {
                  window.open(res.data);
                }
              });
            break;
          case "monthly-agreement":
            api.contractFrameExport({ orderCode, ...values }).then((res) => {
              if (res.code === 200) {
                window.open(res.data);
              }
            });
            break;
          default:
            break;
        }
        setExportModalVisible(false);
      })
      .catch((errorInfo) => {
        // 表单验证失败，处理错误信息
        console.log("Form validation failed:", errorInfo);
      });
  };

  const PurchasePdf = (
    <Form layout="vertical" form={form} initialValues={{}}>
      <Form.Item
        label="甲方指定收货人/收货部门"
        name="takeDelivery"
        rules={[{ required: true, message: "请输入甲方指定收货人/收货部门!" }]}
      >
        <AutoComplete
          style={{ width: "100%" }}
          onSearch={fetchDeliveryOptions} // 当用户输入内容时触发查询
          placeholder="请输入甲方指定收货人/收货部门"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value, option) => {
            const selectedOption = deliveryOptions.find(option => option.value === value);
            if (selectedOption) {
              form.setFieldsValue(selectedOption);
            }
          }}
        >
          {deliveryOptions.map(option => (
            <AutoComplete.Option key={option.value} value={option.value}>
              {option.takeDelivery}
            </AutoComplete.Option>
          ))}
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label="预计交货时间"
        name="deliveryTime"
        rules={[{ required: true, message: "请输入预计交货时间" }]}
      >
        <Input />
      </Form.Item>
      {/* 其他表单项 */}
    </Form>
  );
  const nonMonthlyForm = (
    <Form layout="vertical" form={form} initialValues={{
      secondParty: '飞卓科技（上海）股份有限公司',
      bankB: '上海农商银行金山工业区支行',
      usernameB: '飞卓科技（上海）股份有限公司',
      accountNumberB: '50131000645574253',
      siteB: '上海市金山区春丽路1239号4栋',
      validYear: '2025'
    }}>
      <Form.Item
        label="预付款工作日"
        name="prePaymentDays"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="支付百分比"
        name="paymentPercentage"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="发货工作日"
        name="deliveryDay"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="支付合同总价百分比"
        name="percentageDelivered"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="质保月数"
        name="numberMonths"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      {/* 款到排产和款到发货的单选按钮 */}
      <Form.Item
        label="款到排产/发货"
        name="paymentType"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Radio.Group
          onChange={(e) =>
            form.setFieldsValue({
              percentageDeliveredDays: "",
              percentageShippedDays: "",
            })
          }
          value={form.getFieldValue("paymentType")}
        >
          <Radio value="percentageDelivered">款到排产</Radio>
          <Radio value="percentageShipped">款到发货</Radio>
        </Radio.Group>
      </Form.Item>
      {/* 款到排产对应的工作日输入框 */}
      <Form.Item
        label="款到排产专用多少工作日"
        name="percentageDeliveredDays"
        rules={[{ required: true, message: "请输入预付款工作日!" }]}
      >
        <Input />
      </Form.Item>
      {/* 款到发货对应的工作日输入框 */}
      <Form.Item
        label="款到发货专用多少工作日"
        name="percentageShippedDays"
        rules={[{ required: true, message: "请输入款到发货专用多少工作日!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="交货地点"
        name="deliveryPlace"
        rules={[{ required: true, message: "请输入交货地点!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方指定收货人/收货部门"
        name="takeDelivery"
        rules={[{ required: true, message: "请输入甲方指定收货人/收货部门!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="联系方式"
        name="contactInformation"
        rules={[{ required: true, message: "请输入联系方式!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="电子邮箱"
        name="emailAddress"
        rules={[{ required: true, message: "请输入电子邮箱!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方联系人"
        name="contactPersonA"
        rules={[{ required: true, message: "请输入甲方联系人!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方联系人"
        name="contactPersonB"
        rules={[{ required: true, message: "请输入乙方联系人!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方邮寄地址"
        name="mailingByPartyA"
        rules={[{ required: true, message: "请输入甲方邮寄地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方邮寄地址"
        name="mailingByPartyB"
        rules={[{ required: true, message: "请输入乙方邮寄地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方电子邮箱地址"
        name="partyAddressA"
        rules={[{ required: true, message: "请输入甲方电子邮箱地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方电子邮箱地址"
        name="partyAddressB"
        rules={[{ required: true, message: "请输入乙方电子邮箱地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="有效期年份"
        name="validYear"
        rules={[{ required: true, message: "请输入有效期年份!" }]}
      >
        <Input defaultValue={"2025"} />
      </Form.Item>
      <Form.Item
        label="甲方"
        name="firstParty"
        rules={[{ required: true, message: "请输入甲方!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方"
        name="secondParty"
        rules={[{ required: true, message: "请输入乙方!" }]}
      >
        <Input defaultValue={"飞卓科技（上海）股份有限公司"}/>
      </Form.Item>
      <Form.Item
        label="甲方户名"
        name="usernameA"
        rules={[{ required: true, message: "请输入甲方户名!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方户名"
        name="usernameB"
        rules={[{ required: true, message: "请输入乙方户名!" }]}
      >
        <Input defaultValue={"飞卓科技（上海）股份有限公司"} />
      </Form.Item>
      <Form.Item
        label="甲方银行"
        name="bankA"
        rules={[{ required: true, message: "请输入甲方银行!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方银行"
        name="bankB"
        rules={[{ required: true, message: "请输入乙方银行!" }]}
      >
        <Input defaultValue={"上海农商银行金山工业区支行"} />
      </Form.Item>
      <Form.Item
        label="甲方账号"
        name="accountNumberA"
        rules={[{ required: true, message: "请输入甲方账号!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方账号"
        name="accountNumberB"
        rules={[{ required: true, message: "请输入乙方账号!" }]}
      >
        <Input defaultValue={"50131000645574253"} />
      </Form.Item>
      <Form.Item
        label="甲方地址"
        name="siteA"
        rules={[{ required: true, message: "请输入甲方地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方地址"
        name="siteB"
        rules={[{ required: true, message: "请输入乙方地址!" }]}
      >
        <Input defaultValue={"上海市金山区春丽路1239号4栋"} />
      </Form.Item>
    </Form>
  );
  const monthlyAgreementForm = (
    <Form layout="vertical" form={form} initialValues={{
      secondParty: '飞卓科技（上海）股份有限公司',
      bankB: '上海农商银行金山工业区支行',
      usernameB: '飞卓科技（上海）股份有限公司',
      accountNumberB: '50131000645574253',
      siteB: '上海市金山区春丽路1239号4栋',
      validYear: '2025'
    }}>
      <Form.Item
        label="月结天数"
        name="monthEndDays"
        rules={[{ required: true, message: "请输入月结天数!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="发票后日期"
        name="invoiceDate"
        rules={[{ required: true, message: "请输入发票后日期!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="质保月数"
        name="numberMonths"
        rules={[{ required: true, message: "请输入质保月数!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方联系人"
        name="contactPersonA"
        rules={[{ required: true, message: "请输入甲方联系人!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方联系人"
        name="contactPersonB"
        rules={[{ required: true, message: "请输入乙方联系人!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方邮寄地址"
        name="mailingByPartyA"
        rules={[{ required: true, message: "请输入甲方邮寄地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方邮寄地址"
        name="mailingByPartyB"
        rules={[{ required: true, message: "请输入乙方邮寄地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="甲方电子邮箱地址"
        name="partyAddressA"
        rules={[{ required: true, message: "请输入甲方电子邮箱地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方电子邮箱地址"
        name="partyAddressB"
        rules={[{ required: true, message: "请输入乙方电子邮箱地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="有效期年份"
        name="validYear"
        rules={[{ required: true, message: "请输入有效期年份!" }]}
      >
        <Input defaultValue={"2025"} />
      </Form.Item>
      <Form.Item
        label="甲方"
        name="firstParty"
        rules={[{ required: true, message: "请输入甲方!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方"
        name="secondParty"
        rules={[{ required: true, message: "请输入乙方!" }]}
      >
        <Input defaultValue={"飞卓科技（上海）股份有限公司"}/>
      </Form.Item>
      <Form.Item
        label="甲方户名"
        name="usernameA"
        rules={[{ required: true, message: "请输入甲方户名!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方户名"
        name="usernameB"
        rules={[{ required: true, message: "请输入乙方户名!" }]}
      >
        <Input defaultValue={"飞卓科技（上海）股份有限公司"} />
      </Form.Item>
      <Form.Item
        label="甲方银行"
        name="bankA"
        rules={[{ required: true, message: "请输入甲方银行!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方银行"
        name="bankB"
        rules={[{ required: true, message: "请输入乙方银行!" }]}
      >
        <Input  defaultValue={"上海农商银行金山工业区支行"} />
      </Form.Item>
      <Form.Item
        label="甲方账号"
        name="accountNumberA"
        rules={[{ required: true, message: "请输入甲方账号!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方账号"
        name="accountNumberB"
        rules={[{ required: true, message: "请输入乙方账号!" }]}
      >
        <Input defaultValue={"50131000645574253"} />
      </Form.Item>
      <Form.Item
        label="甲方地址"
        name="siteA"
        rules={[{ required: true, message: "请输入甲方地址!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="乙方地址"
        name="siteB"
        rules={[{ required: true, message: "请输入乙方地址!" }]}
      >
        <Input defaultValue={"上海市金山区春丽路1239号4栋"} />
      </Form.Item>
      {/* ... 其他月结框架协议字段 */}
    </Form>
  );
  const handleExportModalCancel = () => {
    setExportModalVisible(false);
  };
  // const handleMenuClick = (e) => {
  //   const type = e.key;
  //   const orderCodeValue = orderCode; // 使用状态中的 orderCode
  //   switch (type) {
  //     case "monthly":
  //       api
  //         .contractPurchaseExport({ orderCode: orderCodeValue })
  //         .then((res) => {
  //           if (res.code === 200) {
  //             window.open(res.data); // 在新标签页中打开下载链接
  //           }
  //         });
  //       break;
  //     case "non-monthly":
  //       api
  //         .contractNonPurchaseExport({ orderCode: orderCodeValue })
  //         .then((res) => {
  //           //预付款工作日，支付百分比，发货前多少工作日，发货前支付百分比，款到排产多少个工作日交货，款到发货多少个工作日交货，甲方联系人，乙方联系人，甲方邮寄地址，乙方邮寄地址，甲方电子邮箱地址，乙方电子邮箱地址，有效期年份，附件共多少项
  //           if (res.code === 200) {
  //             window.open(res.data); // 在新标签页中打开下载链接
  //           }
  //         });

  //       break;
  //     case "monthly-agreement":
  //       api.contractFrameExport({ orderCode: orderCodeValue }).then((res) => {
  //         //月结天数，发票后日期，甲方联系人，乙方联系人，甲方邮寄地址，乙方邮寄地址，甲方电子邮箱地址，乙方电子邮箱地址，有效期年份
  //         if (res.code === 200) {
  //           window.open(res.data); // 在新标签页中打开下载链接
  //         }
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const menu = (
    <Menu onClick={(record) => showExportModal(record.key)}>
      <Menu.Item key="monthly">月结采购订单</Menu.Item>
      <Menu.Item key="non-monthly">非月结</Menu.Item>
      <Menu.Item key="monthly-agreement">月结框架协议</Menu.Item>
    </Menu>
  );
  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <Form
        form={form}
        name="quotation"
        initialValues={{
          content: ueditorContent,
        }}
        onFinish={onFinish}
      >
        <h2 className="text-center">产 品 购 销 合 同</h2>
        <br />
        <br />
        <Row gutter={90}>
          <Col md={12}>
            <div>
              <Form.Item label="供方" name="supplier">
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="需方编号" name="buyerContract">
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="需方" name="buyer">
                <Input />
              </Form.Item>
            </div>
          </Col>
          <Col md={12}>
            <div>
              {/* <Form.Item label="合同编号" name="contractNo">
                <Input  />
              </Form.Item> */}
            </div>
            <div>
              <Form.Item label="订单编号" name="orderCode">
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="签订时间" name="signTime">
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  showTime
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="签订地点" name="signAddr">
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <br />
        <p>1、标的物品名称、型号、数量及价款：</p>
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <Form.Item
                    name="remark"
                    style={{ marginBottom: 0 }}
                  ></Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <b>合计人民币（大写）：{digitUppercase(totalPrice)}</b>
                    <b>RMB:{totalPrice}</b>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <br />
        <br />
        <Form.Item name="contractTerms">
          <div>
            <RcUeditor
              ueditorUrl="/ueditor/ueditor.all.js"
              ueditorConfigUrl="/ueditor/ueditor.config.js"
              value={ueditorContent}
              editorConfig={editorConfig}
              onChange={(e) => hanldeChage(e, "contractTerms")}
            />
          </div>
        </Form.Item>
        <br />
        <br />
        <div className="ant-table ant-table-bordered">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table className="table">
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" colspan="2">
                      供方
                    </th>
                    <th className="ant-table-cell" colspan="2">
                      需方
                    </th>
                    <th className="ant-table-cell">鉴（公）证意见</th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">单位名称</td>
                    <td className="ant-table-cell">
                      飞卓科技（上海）股份有限公司
                    </td>
                    <td className="ant-table-cell">单位名称</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="buyerCompany"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="4" />
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">单位地址</td>
                    <td className="ant-table-cell">
                      上海市金山区春丽路1239号4栋
                    </td>
                    <td className="ant-table-cell">单位地址</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerAddr" style={{ marginBottom: 0 }}>
                        <Input.TextArea />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">代理人</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="supplierAgent"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">代理人</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerAgent" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">电话</td>
                    <td className="ant-table-cell">021-57274400</td>
                    <td className="ant-table-cell">电话</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerTel" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">传真</td>
                    <td className="ant-table-cell">021-57272066</td>
                    <td className="ant-table-cell">传真</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerFax" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="2">
                      <Form.Item
                        name="handler"
                        label="经办人"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">税号</td>
                    <td className="ant-table-cell">91310116765323346N</td>
                    <td className="ant-table-cell">税号</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="buyerDutyParagraph"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">开户银行</td>
                    <td className="ant-table-cell">
                      上海农商银行金山工业区支行
                    </td>
                    <td className="ant-table-cell">开户银行</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerBank" style={{ marginBottom: 0 }}>
                        <Input.TextArea />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="3">
                      <Form.Item
                        name="zhang"
                        label="鉴（公）证意见（章"
                        style={{ marginBottom: 0 }}
                      />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">帐号</td>
                    <td className="ant-table-cell">50131000645574253</td>
                    <td className="ant-table-cell">帐号</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerNo" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">审核</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="supplierReview"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">审核</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerReview" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="text-center">
          <Button
            className="mr-3"
            onClick={() => {
              // window.history.go(-1);
              window.close();
              // window.open("/index/order/contract");
            }}
          >
            返回
          </Button>
          {/* <Button className="mr-3" type="primary" htmlType="submit">
            提交
          </Button> */}
          {/* <Button
            className="mr-3"
            onClick={() => {
              api
                .contractExport({
                  orderCode: qs.parse(window.location.search.split("?")[1])
                    .orderCode,
                })
                .then((res) => {
                  if (res.code == 200) {
                    let btn = document.createElement("a");
                    btn.download = `订单编号-${
                      qs.parse(window.location.search.split("?")[1]).orderCode
                    }-电子合同.pdf`;
                    btn.href = res.data;
                    btn.target = "_blank";
                    btn.click();
                    btn = null;
                  }
                });
            }}
          >
            导出
          </Button> */}
          <Dropdown overlay={menu} placement="bottomCenter">
            <Button
              className="mr-3"
              onClick={(e) => e.preventDefault()}
              icon={<DownOutlined />}
            >
              导出选项 <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Form>
      <Modal
        title="导出信息"
        visible={exportModalVisible}
        onOk={handleExportModalOk} // 使用 handleExportModalOk 作为 onOk 处理函数
        onCancel={handleExportModalCancel}
      >
        {exportType === "monthly" && PurchasePdf}
        {exportType === "non-monthly" && nonMonthlyForm}
        {exportType === "monthly-agreement" && monthlyAgreementForm}
        {/* <Form.Item>
          <Button type="primary" onClick={handleExportModalOk}>
            提交
          </Button>
        </Form.Item> */}
      </Modal>
    </div>
  );
};

export default ElectronicContract;
