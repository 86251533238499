import React, { useEffect, useRef, useState } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Form,
  Descriptions,
  Button,
  message,
  Modal,
  Input,
  Checkbox,
} from "antd";
import api from "../../api";
import qs from "qs";
import utils from "../../assets/js/public.js";
import "./tabel.less";
import { render } from "less";
import data from "../../assets/js/data.json";

const Details = () => {
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setquotationInfo] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("0.00");
  //erp
  const [MiddleOrderPrdmq, setMiddleOrderPrdmq] = useState([]);
  //xiaogongdan
  const [MiddleOrderTaskQuery, setMiddleOrderTaskQuery] = useState([]);
  //产品对应
  //erp
  const [MiddleOrderPrdmqOne, setMiddleOrderPrdmqOne] = useState([]);
  //xiaogongdan
  const [MiddleOrderTaskQueryOne, setMiddleOrderTaskQueryOne] = useState([]);

  const [contractListId, setContractListId] = useState();
  const [electronicContractId, setElectronicContractId] = useState();
  const [quotationId, setQuotationId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenOne, setIsModalOpenOne] = useState(false);
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
  const [isModalOpenThree, setIsModalOpenThree] = useState(false);
  const [isModalOpenFive, setIsModalOpenFive] = useState(false);
  //复购选择产品
  const [isModalOpenFour, setIsModalOpenFour] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // 调用确定按钮的处理函数
    api
      .copyToOneself({
        orderProductList: selectedProducts,
        repurchaseOrderNumber: proDataSource[0].orderCode,
      })
      .then((result) => {
        console.log(result.message); // 打印出"Success"或者Promise解析后的message值
        // 在这里判断message并显示对应的消息
        if (result.message === "Success") {
          message.success("操作成功");
        } else {
          message.error(result.message); // 假设对于非"Success"的情况，你想显示错误或不同的消息
        }
      })
      .catch((error) => {
        console.error("Promise rejected with error: ", error);
        message.error("操作失败"); // 在Promise被拒绝时显示的消息
      });
    handleOkFour();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalOne = () => {
    setIsModalOpenOne(true);
  };
  const handleOkOne = () => {
    setIsModalOpenOne(false);
  };

  const handleCancelOne = () => {
    setIsModalOpenOne(false);
  };
  const showModalTwo = () => {
    setIsModalOpenTwo(true);
  };
  const handleOkTwo = () => {
    setIsModalOpenTwo(false);
  };
  const handleCancelTWo = () => {
    setIsModalOpenTwo(false);
  };

  const showModalThree = () => {
    setIsModalOpenThree(true);
  };
  const handleOkThree = () => {
    setIsModalOpenThree(false);
  };
  const handleCancelThree = () => {
    setIsModalOpenThree(false);
  };
  const onChange = (checkedValues) => {
    // 将选中的产品 ID 数组转换为字符串数组
    const selectedProductStrings = checkedValues.map(value => String(value));
    // 更新选中的产品列表
    setSelectedProducts(selectedProductStrings);
    // console.log("Selected products:", selectedProductStrings);
  };
  

  const showModalFive = () => {
    setIsModalOpenFive(true);
  };
  const handleOkFive = () => {
    setIsModalOpenFive(false);
  };
  const handleCancelFive = () => {
    setIsModalOpenFive(false);
  };

  const handleOkFour = () => {
    setIsModalOpenFour(false);
  };
  const handleCancelFour = () => {
    setIsModalOpenFour(false);
  };

  const [form] = Form.useForm();

  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "产品名称",
      dataIndex: "",
      width: 200,
      render: (text, data, index) => {
        // console.log(data)
        return data.productTitle || "-";
      },
    },
    {
      title: "品种规格",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <div>
            型号：{data.productModel}
            {(data.productConfig &&
              JSON.parse(data.productConfig).map((e) => {
                return (
                  <div>
                    {e.label}: {e.value}
                  </div>
                );
              })) ||
              "-"}
          </div>
        );
      },
    },
    {
      title: "数量",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (data.productNumber && data.productNumber + "PCS") || "-";
      },
    },
    {
      title: "单价（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (data.salesAmount && data.salesAmount.toFixed(2)) || "-";
      },
    },
    {
      title: "产品折扣",
      dataIndex: "",
      width: 90,
      render: (text, data, index) => {
        const discount =
          data.productAmount > 0
            ? (
                (data.salesAmount * data.productNumber) /
                (data.productAmount * data.productNumber)
              ).toFixed(3)
            : "-";
        return discount;
      },
    },
    {
      title: "总金额（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (
          (data.salesAmount &&
            data.productNumber &&
            (data.salesAmount * data.productNumber).toFixed(2)) ||
          "-"
        );
      },
    },
    {
      title: "对应小工单",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (
          (
            <div>
              <Button
                style={{ margin: 10 }}
                type="primary"
                className="mr-1"
                target="_blank"
                onClick={() => {
                  setMiddleOrderPrdmq(data.middleOrderPrdmqList);
                  setIsModalOpenThree(true);
                }}
              >
                ERP
              </Button>
              <Button
                style={{ margin: 10 }}
                type="primary"
                className="mr-1"
                target="_blank"
                onClick={() => {
                  setMiddleOrderTaskQuery(data.middleOrderTaskQueries);
                  setIsModalOpenFive(true);
                }}
              >
                小工单
              </Button>
              <Button
                style={{ margin: 10 }}
                type="primary"
                className="mr-1"
                target="_blank"
                onClick={() => {
                  const selectedProductStrings = data.id;
                  console.log(selectedProductStrings)
                  const selectedProductList = [selectedProductStrings];
                  // 更新选中的产品列表
                  api
                  .copyToOneself({
                    orderProductList: selectedProductList,
                    repurchaseOrderNumber: proDataSource[0].orderCode,
                  })
                  .then((result) => {
                    console.log(result.message); // 打印出"Success"或者Promise解析后的message值
                    // 在这里判断message并显示对应的消息
                    if (result.message === "Success") {
                      message.success("操作成功");
                    } else {
                      message.error(result.message); // 假设对于非"Success"的情况，你想显示错误或不同的消息
                    }
                  })
                  .catch((error) => {
                    console.error("Promise rejected with error: ", error);
                    message.error("操作失败"); // 在Promise被拒绝时显示的消息
                  });
                }}
              >
                复购此产品
              </Button>
            </div>
          ) || "-"
        );
      },
    },
  ];

  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let res = await api.orderGet({ orderCode });
    console.log(res);
    form.setFieldsValue({
      ...res.data,
    });
    let totalPrice = 0;
    [...res.data.orderProductList].map((e) => {
      console.log(res); // Remove the spread syntax
      totalPrice += e.salesAmount * e.productNumber;
    });
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource([...res.data.orderProductList]);
    setMiddleOrderPrdmq(res.data.middleOrderPrdmqList);
    setMiddleOrderTaskQuery([...res.data.middleOrderTaskQueryList]);
    setMiddleOrderPrdmqOne(res.data.orderProductList.middleOrderPrdmqList);
    setMiddleOrderTaskQueryOne(
      res.data.orderProductList.middleOrderTaskQueries
    );
  };
  useEffect(() => {
    getQuotationInfo();
  }, []);

  useEffect(() => {
    if (quotationInfo?.orderCode) {
      api.orderGetProductionLiaisonDoc(quotationInfo).then((res) => {
        setContractListId(res?.data?.id);
      });
      api.orderGetQuotation(quotationInfo).then((res) => {
        setQuotationId(res?.data?.id);
      });
      api.orderGetContract(quotationInfo).then((res) => {
        setElectronicContractId(res?.data?.id);
      });
    }
  }, [quotationInfo]);

  const ruleMenu =
    (
      localStorage.getItem("ruleMenu") &&
      JSON.parse(localStorage.getItem("ruleMenu"))
    ).find((e) => e.path == "/index/order")?.children || [];
  return (
    <div style={{ margin: "0 auto" }}>
      <Descriptions
        title="订单详情"
        extra={
          <div>
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/order/contract/audit"
              ) >= 0 &&
              (quotationInfo.orderStatus != 2 &&
              quotationInfo.orderStatus != 4 &&
              quotationInfo.orderStatus != 3 ? (
                <Button
                  type="primary"
                  // className="mr-1"
                  target="_blank"
                  onClick={() => {
                    api.orderPutUpdateView({
                      orderCode: quotationInfo.orderCode,
                      orderStatus: 2,
                      userid: quotationInfo.userId,
                      id: quotationInfo.id,
                    });
                    return message.success("审批成功");
                  }}
                >
                  通过
                </Button>
              ) : (
                ""
              ))) ||
              ""}
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/order/contract/audit"
              ) >= 0 &&
              (quotationInfo.orderStatus != 2 &&
              quotationInfo.orderStatus != 4 &&
              quotationInfo.orderStatus != 3 ? (
                <Button
                  type="primary"
                  target="_blank"
                  // className="mr-1"
                  danger
                  onClick={() => {
                    let res = api.orderPutUpdate({
                      orderCode: quotationInfo.orderCode,
                      orderStatus: 3,
                      userId: quotationInfo.userId,
                      id: quotationInfo.id,
                    });
                    setIsModalOpen(true);
                    // return message.success("拒绝成功");
                  }}
                >
                  拒绝
                </Button>
              ) : (
                ""
              ))) ||
              ""}
            {/* <Button
              type="primary"
              className="mr-1"
              target="_blank"
              onClick={() => {
                console.log(MiddleOrderPrdmq);
                setIsModalOpenOne(MiddleOrderPrdmq);
              }}
            >
              ERP订单状态
            </Button>
            <Button
              type="primary"
              className="mr-1"
              target="_blank"
              onClick={() => {
                console.log(MiddleOrderTaskQuery);
                setIsModalOpenTwo(MiddleOrderTaskQuery);
              }}
            >
              小工单工序
            </Button> */}
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/order/contract/export1"
              ) >= 0 && (
                <Button
                  type="primary"
                  className="mr-1"
                  target="_blank"
                  onClick={() => {
                    api
                      .quotationExport({
                        orderCode: quotationInfo.orderCode,
                      })
                      .then((res) => {
                        if (res.code == 200) {
                          let btn = document.createElement("a");
                          btn.download = `订单编号-${quotationInfo.orderCode}-报价单.pdf`;
                          btn.href = res.data;
                          btn.target = "_blank";
                          btn.click();
                          btn = null;
                        }
                      });
                  }}
                >
                  导出报价单
                </Button>
              )) ||
              ""}
            {/* {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/order/contract/export2"
              ) >= 0 && (
                <Button
                  type="primary"
                  className="mr-1"
                  target="_blank"
                  onClick={() => {
                    console.log(quotationInfo);
                    api
                      .contractExport({
                        orderCode: quotationInfo.orderCode,
                      })
                      .then((res) => {
                        if (res.code == 200) {
                          let btn = document.createElement("a");
                          btn.download = `订单编号-${quotationInfo.orderCode}-电子合同.pdf`;
                          btn.href = res.data;
                          btn.target = "_blank";
                          btn.click();
                          btn = null;
                        }
                      });
                  }}
                >
                  导出电子合同
                </Button>
              )) ||
              ""} */}
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/order/contract/export3"
              ) >= 0 && (
                <Button
                  type="primary"
                  className="mr-1"
                  target="_blank"
                  onClick={() => {
                    api
                      .productionLiaisonDoc({
                        orderCode: quotationInfo.orderCode,
                      })
                      .then((res) => {
                        if (res.code == 200) {
                          let btn = document.createElement("a");
                          btn.download = `订单编号-${quotationInfo.orderCode}-生产联络单.pdf`;
                          btn.href = res.data;
                          btn.target = "_blank";
                          btn.click();
                          btn = null;
                        }
                      });
                  }}
                >
                  导出生产联络单
                </Button>
              )) ||
              ""}
            <Button
              onClick={() => {
                // window.open("/index/order/contract");
                window.close();
              }}
            >
              返回
            </Button>
          </div>
        }
      >
        <Descriptions.Item label="订单编号">
          {quotationInfo?.orderCode || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="订单状态">
          {quotationInfo?.orderStatusDesc || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
          {quotationInfo?.createTime || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="订单原价">
          {quotationInfo?.productAmount || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="订单实际销售价">
          {quotationInfo?.salesAmount || "0"}
        </Descriptions.Item>
        <Descriptions.Item label="订单折扣比">
          {quotationInfo?.divide || "0"}
        </Descriptions.Item>
        <Descriptions.Item label="创建人">
          {quotationInfo?.nickName || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="客户名称">
          {quotationInfo?.custName || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="业务员名称">
          {quotationInfo?.contacts1 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="付款时效">
          {quotationInfo?.quotationDesc3 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="订单备注">
          {quotationInfo?.remark || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="拒绝原因">
          {quotationInfo?.releaseCause || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="技术审批节点">
          {quotationInfo?.technicalSupportApproval || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="主管审批节点">
          {quotationInfo?.approvalBySupervisor || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="罗总审批节点">
          {quotationInfo?.approvalByManager || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="文件地址">
          {(quotationInfo?.enterId == 27) | (quotationInfo?.enterId == 28) | (quotationInfo?.enterId == 134) | (quotationInfo?.enterId == 61) ? (
            <a href={quotationInfo.fileAddress} target="_blank">
              {quotationInfo.fileAddress}
            </a>
          ) : (
            "-"
          )}
        </Descriptions.Item>
      </Descriptions>
      <Form name="quotation" form={form} initialValues={{}}>
        <br />
        <br />
        <p>产品名称、型号、数量及价款：</p>
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <b />
                    <b>RMB：{totalPrice}</b>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <br />
        <br />
      </Form>
      <Modal
        title="拒绝原因"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Input.TextArea
            name="releaseCause"
            placeholder="该内容会直接发送给下单人"
            onBlur={async (e) => {
              await api.orderreasonsForRefusal({
                id: data.id,
                orderCode: quotationInfo.orderCode,
                orderId: quotationInfo.id,
                // checked: e.target.checked,
                releaseCause: e.target.value,
              });
            }}
          />
        </Form.Item>
      </Modal>

      <Modal
        title="ERP"
        visible={isModalOpenOne}
        onOk={handleOkOne}
        onCancel={handleCancelOne}
        maskClosable={false}
      >
        <form>
          <Form.Item style={{ marginBottom: 10 }}>
            {MiddleOrderPrdmq.map((e) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  工序编号：{e.fmaterialidFNumber}，工序状态为：{e.fstatus}
                  <br></br>
                  排产开始时间为{e.fplanStartDate}，排产结束时间
                  {e.fplanStartDate}
                </div>
              );
            })}
          </Form.Item>
        </form>
      </Modal>

      <Modal
        title="小工单"
        visible={isModalOpenTwo}
        onOk={handleOkTwo}
        onCancel={handleCancelTWo}
        maskClosable={false}
      >
        <form>
          <Form.Item style={{ marginBottom: 10 }}>
            {MiddleOrderPrdmq.map((e) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  当前工序为：{e.processName}
                  <br></br>
                  计划数量为{e.planAmount}，良品数为{e.fineAmount}
                  <br></br>
                  计划结束时间为{e.processEndTime}，实际结束时间为
                  {e.processEndRealTime}
                </div>
              );
            })}
          </Form.Item>
        </form>
      </Modal>

      <Modal
        title="查看ERP"
        visible={isModalOpenThree}
        onOk={handleOkThree}
        onCancel={handleCancelThree}
        maskClosable={false}
      >
        <form>
          <Form.Item style={{ marginBottom: 10 }}>
            {MiddleOrderPrdmq.map((e) => {
              // console.log(MiddleOrderPrdmqOne)
              return (
                <div style={{ marginBottom: 10 }}>
                  工序编号：{e.fmaterialidFNumber}，工序状态为：{e.fstatus}
                  {/* {e.map=()=>{
                    if(e.fstatus=1){
                      e.fstatus = "计划"
                    }
                    if(e.fstatus=2){
                      e.fstatus = "计划确认"
                    }
                    if(e.fstatus=1){
                      e.fstatus = "下达"
                    }
                    if(e.fstatus=1){
                      e.fstatus = "开工"
                    }
                    return(
                      <div>
                        {e.fstatus}
                      </div>
                    )
                  }} */}
                  <br></br>
                  排产开始时间为{e.fplanStartDate}，排产结束时间
                  {e.fplanStartDate}
                </div>
              );
            })}
          </Form.Item>
        </form>
      </Modal>
      <Modal
        title="查看小工单"
        visible={isModalOpenFive}
        onOk={handleOkFive}
        onCancel={handleCancelFive}
        maskClosable={false}
      >
        <form>
          <Form.Item style={{ marginBottom: 10 }}>
            {MiddleOrderTaskQuery.map((e) => {
              // console.log(MiddleOrderTaskQueryOne);
              return (
                <div style={{ marginBottom: 10 }}>
                  当前工序为：{e.processName}
                  <br></br>
                  计划数量为{e.planAmount}，良品数为{e.fineAmount}
                  <br></br>
                  计划结束时间为{e.processEndTime}，实际结束时间为
                  {e.processEndRealTime}
                </div>
              );
            })}
          </Form.Item>
        </form>
      </Modal>
      <Modal
        title="请选择要复购的产品"
        visible={isModalOpenFour}
        onOk={handleOk}
        onCancel={handleCancelFour}
        maskClosable={false}
      >
        <Form>
          <Form.Item style={{ marginBottom: 10 }}>
            <Checkbox.Group onChange={onChange}>
              {proDataSource.map((product, index) => (
                <Checkbox key={product.id} value={product.id}>
                  {`${index + 1}. ${product.productTitle}`}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="primary"
        className="mr-1"
        target="_blank"
        onClick={() => {
          //循环吧所有id写入进去，复购
          {proDataSource.map((product, index) => (
            setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product.id])
          ))}
          api
          .copyToOneself({
            orderProductList: selectedProducts,
            repurchaseOrderNumber: proDataSource[0].orderCode,
          })
          message.success('复购成功');
        }}
      >
        复制订单
      </Button>
    </div>
  );
};

export default Details;
